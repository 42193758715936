import * as React from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import * as d3 from "d3";
import {arrayToCSV, download} from "../../js/dataExport";
import {formatUnitId} from "../../js/format";
import {logEvent} from "../../js/interface";

export default function ContextMenu({ children, unitsData, handleClickCompareUnit }) {
  const [contextMenu, setContextMenu] = React.useState(null);
  const [selectedUnit, setSelectedUnit] = React.useState("unit");
  const [tenantName, setTenantName] = React.useState("unit");

  const handleContextMenu = (event) => {
    event.preventDefault();
    console.log("contextmenu from container")

    const datum = d3.select(event.target).datum()

    if (typeof(datum) === "undefined") {
      console.log("contextmenu, no unit selected")
      setTenantName(null)  // TODO: use last selected unit?
    } else {
      setSelectedUnit(datum.unit_id);
      const unitData = unitsData.find(dd => parseInt(dd.unit_id) === parseInt(datum.unit_id))
      if (unitData) {
        if (unitData.trading_as) {
          setTenantName(unitData.trading_as)
        } else if (unitData.tenant) {
          setTenantName(unitData.tenant)
        } else {
          setTenantName("unit")
        }
      } else {
        setTenantName("unit")
      }
    }

    setContextMenu(
      contextMenu === null
        ? {
          mouseX: event.clientX + 2,
          mouseY: event.clientY - 6,
        }
        : // repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu
          // Other native context menus might behave different.
          // With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.
        null,
    );
  };

  const handleClickDownloadUnitData = (unitId) => {
    const unitData = unitsData.find(d => d.unit_id === unitId)

    download(
      `unit ${formatUnitId(unitId)} export.csv`,
      arrayToCSV(
        [unitData],
        ['unit_id', 'demise', 'leaseid', 'tenant', 'trading_as', 'start_date', 'end_date', 'rent', 'original_rent', 'turnover_rent', 'break_option', 'break_notice_date', 'break_option_type', 'outside1954_act', 'lower_ground', 'lower_mezz', 'upper_ground', 'upper_mezz', 'total_area', 'service_charge_2022', 'insurance_2021_2022', 'rates_payable_2021_2022', 'total_occupational_costs', 'turnover_2018', 'turnover_2019', 'turnover_2020', 'turnover_2021', 'turnover_2022', 'last_year_turnover', 'event_type', 'lease_commencement_date', 'erv', 'turnover_percentage', 'rent_free_months', 'lease_type', 'notes', 'category', 'vacant', 'rent_psf', 'rent_to_sales_ratio', 'occupancy_cost_ratio', 'partition_date', 'sales_density', 'unit_name', 'lease_expiry', "leasingStatus"]))

    logEvent({ eventName: "downloadUnitData" })
  }

  const handleClose = () => {
    setContextMenu(null);
  };

  return (
    <div onContextMenu={handleContextMenu}>
      {children}
      <StyledMenu
        sx={{ borderRadius: "20px" }}
        open={contextMenu !== null}
        onClose={handleClose}
        anchorReference="anchorPosition"
        anchorPosition={
          contextMenu !== null
            ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
            : undefined
        }
      >
        {tenantName === null ?
          <MenuItem key={"no selection"} disabled onClick={handleClose}>(no unit selected)</MenuItem>
          :
          [
            <MenuItem key={"add-to-compare"} onClick={(e) => {
              handleClickCompareUnit(selectedUnit)
              handleClose(e)
            }}>Add {tenantName} to compare</MenuItem>,
            <MenuItem key={"export-data"} onClick={(e) => {
              handleClickDownloadUnitData(selectedUnit)
              handleClose(e)
            }}>Export unit data</MenuItem>
          ]
        }
      </StyledMenu>
    </div>
  );
}

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 20,
    // minWidth: 180,
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '10px 0',
    },
    '& .MuiMenuItem-root': {
      // padding: "0 10px",
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
    },
  },
}));
