import Stack from "@mui/material/Stack";
import { TabProps } from "./TabGroup";
import Typography from "@mui/material/Typography";
import { useState } from "react";
import Note from "../../Note/Note";
import NotesEditor from "../../NotesEditor/NotesEditor";

interface NotesTabProps extends TabProps {
  //
}

export default function NotesTab(props: NotesTabProps) {
  const [showArchivedNotes, setShowArchivedNotes] = useState(false);

  const priorityNote = props.notes.find(
    (d) => d.highPriority && d.unitId === props.selectedUnit
  );

  return (
    <Stack direction={"column"} spacing={1} style={{ width: "100%" }}>
      <Typography>
        <span
          onClick={() => setShowArchivedNotes(false)}
          style={{
            color: showArchivedNotes ? "var(--black)" : "var(--red)",
            fontWeight: showArchivedNotes ? "normal" : "bold",
            cursor: showArchivedNotes ? "pointer" : "default",
            textDecoration: "underline",
          }}
        >
          Active Notes
        </span>
        <span style={{ color: "var(--grey2)" }}>&nbsp;&nbsp;|&nbsp;&nbsp;</span>
        <span
          onClick={() => setShowArchivedNotes(true)}
          style={{
            color: showArchivedNotes ? "var(--red)" : "var(--black)",
            fontWeight: showArchivedNotes ? "bold" : "normal",
            cursor: showArchivedNotes ? "default" : "pointer",
            textDecoration: "underline",
          }}
        >
          Archive
        </span>
      </Typography>
      <Stack direction={"row"} spacing={2}>
        {props.isVerifiedUser && (
          <NotesEditor
            unitId={props.selectedUnit}
            handleRefreshNotesForUnit={props.handleRefreshNotesForUnit}
            handleError={props.handleError}
          />
        )}
        <Stack
          direction={"column"}
          spacing={1}
          style={{ width: "100%", overflowY: "scroll", maxHeight: 300 }}
        >
          {props.notes
            .filter(
              (d) =>
                d.unitId === props.selectedUnit &&
                ((showArchivedNotes && d.archived) ||
                  (!showArchivedNotes && !d.archived))
            )
            .sort(
              (a, b) =>
                (b.highPriority ? 1e8 : 0) +
                b.created -
                ((a.highPriority ? 1e8 : 0) + a.created)
            )
            .map((d) => (
              <Note
                key={d.id}
                {...d}
                handleRefreshNotesForUnit={props.handleRefreshNotesForUnit}
                handleError={props.handleError}
                isVerifiedUser={props.isVerifiedUser}
                priorityNote={priorityNote}
              />
            ))}
        </Stack>
      </Stack>
    </Stack>
  );
}
