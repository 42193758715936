import {useState, useEffect} from "react";
import {Box, Stack, Typography} from "@mui/material";
import Category from "../Category/Category";
import DividerDashed from "../DividerDashed/DividerDashed";
import {categoryLabeltoColourMap} from "../../js/colourUtils";

/**
 * If all categories are selected then each individual category will appear as not selected
 * @param onChange: Handles onChange event
 * @returns {JSX.Element}
 */
const CategorySelect = ({ onChange }) => {
  const allCategories = Object.keys(categoryLabeltoColourMap);
  const countCategories = allCategories.length;

  const [selectedCategories, setSelectedCategories] = useState(allCategories);
  const selectAllCategories = selectedCategories.length === 0 || selectedCategories.length === countCategories;

  const handleToggleCategory = (categoryLabel) => {
    setSelectedCategories(prevState => {
      // special case: if all categories are selected, deselect everything except the new selection
      if (selectedCategories.length === countCategories) {
        return [categoryLabel]
      }
      else if (prevState.indexOf(categoryLabel) === -1) {
         return [categoryLabel, ...prevState]
      } else {
        return [...prevState.filter(d => d !== categoryLabel)]
      }
    })
  }

  const handleToggleAll = (categoryLabel) => {
    setSelectedCategories(prevState => {
      // if all are selected, do nothing
      if (selectedCategories.length === countCategories) {
        return prevState
      }
      else {
        return [...allCategories]
      }
    })
  }

  // every time the selection changes, call the onChange handler
  useEffect(() => {
    onChange(selectedCategories)
  }, [selectedCategories])

  return (
    <Box style={{ width: "100%" }}>
      <Typography variant="h5" style={{ marginBottom: 8 }}>Retail Category</Typography>
      <Stack direction={"column"} spacing={1} style={{ width: "100%" }}>
        <Category key={"all"} name={"All categories"} selected={selectAllCategories} onChange={handleToggleAll} />
        <DividerDashed />
        {allCategories.map((name) => {
          return [
            name === "Vacant" && <DividerDashed key={"category-vacant-divider"} />,
            <Category key={name} name={name} selected={!selectAllCategories && selectedCategories.indexOf(name) > -1}
                      onChange={handleToggleCategory} />
          ]
        })}

      </Stack>
    </Box>
  )
}

export default CategorySelect;
