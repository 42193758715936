import { useState } from "react";
import {Box, Collapse, Stack} from '@mui/material'
import Notification from "../Notification/Notification";

interface NotificationCenterProps {
  errors: Error[];  // client errors, must have unique names
}


function parseError(error: Error) {
  let message = error.message

  if (message === "Failed to fetch") {
    message = "Couldn't connect to API to download data"
  }
  let path = ""
  return { message, path }
}

const NotificationCenter = ({ errors, }: NotificationCenterProps) => {

  const [open, setOpen] = useState(true)
  const [dismissedErrors, setDismissedErrors] = useState<string[]>([])

  return (
    <Box style={{ width: 500, position: "absolute", bottom: 5, right: 5, zIndex: 999 }}>
      <Collapse in={open}>
        {errors.filter(e => dismissedErrors.indexOf(e.name) === -1).map((error) => {

          const { message } = parseError(error)

          return (
            <Notification
              key={error.name}
              severity={"error"}
              message={message}
              onClose={() => {
                setDismissedErrors(prevState => [...prevState, error.name])
              }}
            />
          )
        })}
      </Collapse>
    </Box>
  )
}

export default NotificationCenter;
