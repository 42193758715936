import { Divider, Stack, Typography } from "@mui/material";
import Button from "../components/Button/Button";
import CategorySelect from "../components/CategorySelect/CategorySelect";
import DateSelect from "../components/DateSelect/DateSelect";
import DateRangeSelect from "../components/DateRangeSelect/DateRangeSelect";
import FilterMenu from "../components/FilterMenu/FilterMenu";
import Legend from "../components/Legend/Legend";
import Map from "../components/Map/Map";
import OverlaySelect from "../components/OverlaySelect/OverlaySelect";
import UnitSizeSelect, { MAX_VALUE } from "../components/UnitSizeSelect/UnitSizeSelect";
import SearchToggle from "../components/SearchToggle/SearchToggle";
import Search from "../components/Search/Search";
import UnitDetails from "../components/UnitDetails/UnitDetails";
import { calculateCategoryMAT, calculateMAT } from "../js/turnover";
import { categoryLabeltoColourMap } from "../js/colourUtils";
import type { LeasingScheduleUnit } from "../types/LeasingScheduleUnit";
import type { CleanMapRow } from "../types/CleanMapRow";
import React, { useEffect, useMemo, useState } from "react";
import { Turnover } from "../types/Turnover";
import { Note } from "../types/Note";

export interface UnitMapProps {
  isInternal: boolean;
  isVerifiedUser: boolean;
  units: LeasingScheduleUnit[];
  selectedUnit: number;
  otherUnits: number[];
  unitMap: CleanMapRow[];
  handleClickUnit: (unitId: number) => void;
  handleClickCompareUnit: (unitId: number) => void;
  handleEditUnit: (
    unit_document_id: any,
    unitId: number,
    updatedFields: any
  ) => void;
  handleError: (error: any) => void;
  handleSearchSelectUnit: (e: any, unit: any) => void;
  idToken: string;
  floor: number | null;
  setFloor: React.Dispatch<React.SetStateAction<number | null>>;
  selectedMonth: number;
  selectedYear: number;
  setSelectedMonth: React.Dispatch<React.SetStateAction<number>>;
  setSelectedYear: React.Dispatch<React.SetStateAction<number>>;
  turnoverData: Turnover[];
  dataOverlay: string;
  setDataOverlay: React.Dispatch<React.SetStateAction<string>>;
  notes: Note[];
  handleRefreshNotesForUnit: (unitId: any) => void;
  comparisonUnits: number[];
  handleClickCompareOtherUnits: (otherUnits: number[]) => void;
  isHighlightingUnits: boolean;
  setIsHighlightingUnits: React.Dispatch<React.SetStateAction<boolean>>;
}

export interface DateRange {
  startDate?: Date;
  endDate?: Date;
}

export default function UnitMap(props: UnitMapProps) {
  const [filterResetCounter, setFilterResetCounter] = useState(0);
  const [leaseExpiryRange, setLeaseExpiryRange] = useState<DateRange>({});
  const [breakOptionRange, setBreakOptionRange] = useState<DateRange>({});

  const [selectedUnitSize, setSelectedUnitSize] = useState([0, MAX_VALUE]); // <number[2]>
  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);

  const [unitData, setUnitData] = useState<LeasingScheduleUnit | null>(null);
  const [otherUnitsData, setOtherUnitsData] = useState<LeasingScheduleUnit[]>([]);

  useEffect(() => {
    const unitsData = props.units.filter(
      (d) => props.selectedUnit === d.unit_id
    );
    console.log("[ UnitMap.tsx ] Selected units data", unitsData);

    if (unitsData.length > 0) {
      setUnitData(unitsData[0]);

      const otherUnits = props.units.filter(
        (d) => !! props.otherUnits.find((unitId) => d.unit_id === unitId)
      );

      console.log("[ UnitMap.tsx ] Other units data: ", otherUnits);
      setOtherUnitsData(otherUnits);
    } else {
      setUnitData(null);
      setOtherUnitsData([]);
    }
  }, [props.units, props.selectedUnit, props.otherUnits]);

  const turnoverCalculatedValues = useMemo(() => {
    console.log("[ UnitMap.tsx ] Recalculating MAT Turnover values");
    if (!props.units) {
      return [];
    }

    return props.units.map((d) => {
      return {
        unit_id: d.unit_id,
        ...calculateMAT(
          props.turnoverData.filter((dd) => dd.unit_id === d.unit_id),
          props.selectedYear,
          props.selectedMonth
        ),
      };
    });
  }, [
    props.units,
    props.turnoverData,
    props.selectedMonth,
    props.selectedYear,
  ]);

  // calculate category averages
  // logic: category averages ignore any units that don't have a full year of turnover
  // note: this only runs when turnoverCalculatedValues is updated
  const turnoverCalculatedValuesCategories = useMemo(() => {
    if (!props.units) {
      return [];
    }
    const turnoverValuesWithCategory = turnoverCalculatedValues.map((d) => {
      const unitData = props.units.find((dd) => dd.unit_id === d.unit_id);
      let category = "Null";
      if (unitData) {
        category = unitData.category;
      }
      return { ...d, category };
    });

    const matCalcs = calculateCategoryMAT(
      Object.keys(categoryLabeltoColourMap),
      turnoverValuesWithCategory
    );

    return matCalcs;
  }, [props.units, turnoverCalculatedValues]);

  const handleFilterReset = () => {
    setFilterResetCounter((prevCounter) => prevCounter + 1);
    setLeaseExpiryRange({
      startDate: undefined,
      endDate: undefined,
    });
    setBreakOptionRange({
      startDate: undefined,
      endDate: undefined,
    });
  };

  const showResetButton =
    selectedUnitSize[0] !== 0 ||
    selectedUnitSize[1] !== MAX_VALUE ||
    selectedCategories.length !== 13 ||
    !!leaseExpiryRange.startDate ||
    !!leaseExpiryRange.endDate ||
    !!breakOptionRange.startDate ||
    !!breakOptionRange.endDate;

  return (
    <Stack
      direction={"row"}
      sx={{ height: "calc(100vh - 70px - 16px - 16px)" }}
    >
      <FilterMenu>
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          style={{ height: 32 }}
        >
          <Typography variant={"h4"}>Filters</Typography>
          {showResetButton && (
            <Button label={"Reset"} onClick={handleFilterReset} disabled={false} variant={undefined} />
          )}
        </Stack>
        <Divider />
        <UnitSizeSelect
          key={"unit-size-select-" + filterResetCounter}
          onChange={(values: any) => setSelectedUnitSize(values)}
        />
        <Divider />
        <DateRangeSelect
          key={"lease-expiry-select-" + filterResetCounter}
          dateRange={leaseExpiryRange}
          setDateRange={setLeaseExpiryRange}
          title={"Lease Expiry"}
        />
        <DateRangeSelect
          key={"break-option-select-" + filterResetCounter}
          dateRange={breakOptionRange}
          setDateRange={setBreakOptionRange}
          title={"Break Option"}
        />
        <Divider />
        <CategorySelect
          key={"category-select-" + filterResetCounter}
          onChange={(categories: any) => setSelectedCategories(categories)}
        />
        <Divider />
        <OverlaySelect
          key={"overlay-select"}
          onChange={props.setDataOverlay}
          isInternal={props.isInternal}
        />
        <DateSelect
          dataOverlay={props.dataOverlay}
          selectedMonth={props.selectedMonth}
          setSelectedMonth={props.setSelectedMonth}
          selectedYear={props.selectedYear}
          setSelectedYear={props.setSelectedYear}
        />
      </FilterMenu>

      <Stack style={{ position: "relative", height: "calc(100vh - 70px)" }}>
        <Map
          key={props.unitMap.length}
          unitsData={props.units}
          turnoverCalculatedValues={turnoverCalculatedValues}
          turnoverCalculatedValuesCategories={
            turnoverCalculatedValuesCategories
          }
          breakOptionRange={breakOptionRange}
          unitMap={props.unitMap}
          notes={props.isInternal ? props.notes : []}
          selectedUnit={props.selectedUnit}
          otherUnits={props.otherUnits}
          leaseExpiryRange={leaseExpiryRange}
          handleClickUnit={props.handleClickUnit}
          selectedCategories={selectedCategories}
          floor={props.floor}
          setFloor={props.setFloor}
          selectedUnitSize={selectedUnitSize}
          dataOverlay={props.dataOverlay}
          handleClickCompareUnit={props.handleClickCompareUnit}
          isHighlightingUnits={props.isHighlightingUnits}
        />
        <Legend dataOverlay={props.dataOverlay} />
        <SearchToggle>
          <Search
            unitsData={props.units}
            handleSearchSelectUnit={props.handleSearchSelectUnit}
            handleClickCompareUnit={props.handleClickCompareUnit}
            comparisonUnits={props.comparisonUnits}
            includeCategories={undefined}
            handleSearchSelectCategory={undefined}
          />
        </SearchToggle>
        {props.selectedUnit !== null && (
          <UnitDetails
            key={`${props.selectedYear}-${props.selectedMonth}`}
            unitData={unitData}
            otherUnitsData={otherUnitsData}
            unitTurnoverData={turnoverCalculatedValues.find(
              (d) => d.unit_id === props.selectedUnit
            )}
            notes={props.notes}
            handleRefreshNotesForUnit={props.handleRefreshNotesForUnit}
            isVerifiedUser={props.isVerifiedUser}
            isInternal={props.isInternal}
            selectedUnit={props.selectedUnit}
            handleError={props.handleError}
            handleEditUnit={props.handleEditUnit}
            handleClickUnit={props.handleClickUnit}
            handleClickCompareOtherUnits={props.handleClickCompareOtherUnits}
            comparisonUnits={props.comparisonUnits}
            isHighlightingUnits={props.isHighlightingUnits}
            setIsHighlightingUnits={props.setIsHighlightingUnits}
          />
        )}
      </Stack>
    </Stack>
  );
}
