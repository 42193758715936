import {Box, Stack, Typography} from "@mui/material";
import {colours} from "../../theme"
import * as colourUtils from "../../js/colourUtils";
import {turnoverOverlayToLabelMap} from "../../js/turnover";


const dataOverlayToPaletteMap = {
  leasing_status: colourUtils.leasingStatusLabelToColourMap,
  turnover: colourUtils.turnoverLabeltoColourMap,
  // sales_density: colourUtils.salesDensityLabeltoColourMap,  // this is the continuous scale, which isn't being used now
  rsr: colourUtils.rsrLabeltoColourMap,
  ocr: colourUtils.ocrLabeltoColourMap,
  lease_expiry: colourUtils.leaseExpiryLabelToColourMap,
  break_option: colourUtils.breakOptionLabelToColourMap,
  mat_ocr: colourUtils.ocrLabeltoColourMap,
}

const leasingStatusLabelToActualLabelMap = {
  "None": "None",
  "Exchanged / Completed": "Exchanged",
  "Solicitors Instructed": "Solicitors Instructed",
  "Priority": "Priority",
  "Short / Medium Term": "Short / Medium Term",
  "Redevelopment / Recon Opportunity": "Redevelopment",
  "CVA / Admin": "Admin",
}

const Legend = ({ dataOverlay }) => {

  if (dataOverlay === "category" || dataOverlay === "none") return;

  // if there are more than 7 different values, make the sections smaller
  const width = dataOverlayToPaletteMap[dataOverlay] ? (Object.keys(dataOverlayToPaletteMap[dataOverlay]).length > 7 ? 90 : 120) : 120

  let palette;

  const title = {...colourUtils.dataOverlayToLabelMap, ...turnoverOverlayToLabelMap}[dataOverlay]

  if (dataOverlay === "leasing_status") {
    // Leasing Status overlay has a simplified status

    palette = (
      <Stack direction={"column"} spacing={0.5}>
        {Object.keys(leasingStatusLabelToActualLabelMap).map(label => (
          label === "None" ? null :
          <Stack key={label} direction={"row"} alignItems={"center"} spacing={1}>
            <svg width="20" height="16">
              <rect width="20" height="16" strokeWidth={label === "Redevelopment / Recon Opportunity" ? 8 : 0}
                    stroke={colours.red}
                    fill={colourUtils.leasingStatusLabelToColourMap[leasingStatusLabelToActualLabelMap[label]]}/>
            </svg>
            <Typography>{label}</Typography>
          </Stack>
        ))}
      </Stack>
    )
  }
  else if (dataOverlay === "sales_density" || dataOverlay === "mat_density" || dataOverlay === "mat_density_monthly") {
    // dynamic colour range
    const map = {
      "Very Low": colours.red3,
      "Low": colours.red2,
      "Medium": colours.yellow,
      "High": colours.green,
      "Very high": colours.green2,
    }
    // values from 100 to 550
    const values = [...Array(50).keys()].map(d => d / 50 * 450 + 100)
    palette = (
      <>
        <Stack direction={"row"} justifyContent={"space-between"}>
          {Object.keys(map).map(label => {
            return (
                <Typography>{label}</Typography>
            )
          })}
        </Stack>
        <svg height={5} width={500}>
          {values.map((d, i) => <rect key={i} fill={colourUtils.salesDensityDynamicColourScale(d, 1000001)} x={i * 10} width={10} height={5} y={0} />)}
        </svg>
      </>
    )
    //
    // palette = (
    //   <Stack direction={"row"}>
    //     {Object.keys(map).map(label => {
    //       return (
    //         <Stack direction={"column"} spacing={1}>
    //           <Typography>{label}</Typography>
    //           <Box style={{ width: width, height: 5, background: map[label] }} />
    //         </Stack>
    //       )
    //     })}
    //   </Stack>
    // )

  }
  else if (dataOverlayToPaletteMap[dataOverlay]) {
    // discrete colour range
    palette = (
      <Stack direction={"row"}>
        {Object.keys(dataOverlayToPaletteMap[dataOverlay]).map(label => {
          return (
            <Stack direction={"column"} spacing={1}>
              <Typography>{label}</Typography>
              <Box style={{ width: width, height: 5, background: dataOverlayToPaletteMap[dataOverlay][label] }} />
            </Stack>
          )
        })}
      </Stack>
    )
  } else if (dataOverlay === "mat_growth" || dataOverlay === "mat_growth_monthly" || dataOverlay === "mat_vs_category" || dataOverlay === "mat_vs_category_monthly") {
    // continuous colour range
    // create array of 50 values between -0.1 and 0.1
    const values = [...Array(50).keys()].map(d => (d - 25) * 0.1 / 25)
    palette = (
      <>
        <Stack direction={"row"} justifyContent={"space-between"}>
          <Typography>&lt; -10%</Typography>
          <Typography>0%</Typography>
          <Typography>&gt; 10%</Typography>
        </Stack>
        <svg height={5} width={600}>
          <rect key={"min"} fill={colours.red3} x={0} width={50} height={5} y={0} />
          {values.map((d, i) => <rect key={i} fill={colourUtils.turnoverGrowthRateColourScale(d)} x={i * 10 + 50} width={10} height={5} y={0} />)}
          <rect key={"max"} fill={colours.green2} x={550} width={50} height={5} y={0} />
        </svg>
      </>
    )

  }

  return (
    <Stack direction={"column"} spacing={1} style={{ position: "absolute", top: 20, left: 20, borderRadius: 20, background: colours.white, padding: 20 }}>
      <Typography style={{fontWeight: "bold"}}>{title}</Typography>
        {palette}
    </Stack>
  )
}

export default Legend;
