import {Stack} from "@mui/material";
import "./FilterMenu.css"

const FilterMenu = ({ children }) => {
  return (
    <Stack className={"FilterMenu"} direction={"column"} spacing={2} >
      {children}
    </Stack>
  )
}

export default FilterMenu;
