import WarehouseIcon from "@mui/icons-material/Warehouse";
import UnitDetailsIconButton from "./UnitDetailsIconButton";

export interface OtherUnitsButtonProps {
  active: boolean;
  storageCount: number;
  tradingAs: string;
  onClick: () => void;
}

export default function OtherUnitsButton(props: OtherUnitsButtonProps) {
  return (
    <UnitDetailsIconButton
      active={props.active}
      badgeCount={props.storageCount}
      onClick={props.onClick}
      tooltip={`All ${props.tradingAs} Units`}
    >
      <WarehouseIcon sx={{ height: 24, width: 24 }} />
    </UnitDetailsIconButton>
  );
}
