import * as React from 'react';
import { TextareaAutosize } from '@mui/base/TextareaAutosize';
import { styled } from '@mui/material/styles';

export default function TextArea(props) {
  const StyledTextArea = styled(TextareaAutosize)(({ theme }) => `
    width: 350px;
    min-height: 200px;
    font-family: var(--font-family);
    font-size: 14px;
    padding: 12px;
    border-radius: 10px;
  
    &:hover {
      border-color: var(--blue2);
    }
  
    &:focus {
      border-color: var(--blue2);
      box-shadow: 0 0 0 2px var(--blue2);
    }
  
    // firefox
    &:focus-visible {
      outline: 0;
    }
  `,
  );

  return <StyledTextArea {...props} aria-label="empty textarea" placeholder="Add note" />;
}


export function SimpleTextArea(props) {
  const StyledTextArea = styled(TextArea)(({ theme }) => `
    width: calc(100% - 24px);
    min-height: 120px;
    font-family: var(--font-family);
    font-size: 14px;
    padding: 12px;
    border-radius: 10px;
  
    &:hover {
      border-color: var(--blue2);
    }
  
    &:focus {
      border-color: var(--blue2);
    }
  
    // firefox
    &:focus-visible {
      outline: 0;
    }
  `,
  );

  return <StyledTextArea {...props} aria-label="empty textarea" placeholder="Add note" />;
}