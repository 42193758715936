import { useCallback, useEffect, useState } from "react";
import { Stack } from "@mui/material";
import SignInScreen from "./components/SignInScreen/SignInScreen";
import NotificationCenter from "./components/NotificationCenter/NotificationCenter";
import { get, getCSV, logEvent, post } from "./js/interface";
import { cleanLeasingScheduleRow, cleanMapRow } from "./js/cleanData";
import useFirebaseAuth from "./hooks/useFirebaseAuth";
import AppBar from "./components/AppBar/AppBar";
import UnitMap from "./views/UnitMap";
import UnitComparison from "./views/UnitComparison";


console.log("version: 2.1.11 (release v1.5.1)")
logEvent({ eventName: "appLoaded" })

function App() {

  const [isSignedIn, setIsSignedIn] = useState(false); // Local signed-in state.
  const idToken = useFirebaseAuth(isSignedIn).token // Firebase id token.

  const [isInternal, setIsInternal] = useState(true);
  const [page, setPage] = useState("map");

  const [userName, setUserName] = useState(null)
  const [isVerifiedUser, setIsVerifiedUser] = useState(false)
  const [units, setUnits] = useState([])  // this is leasing_schedule.csv
  const [liveUnitData, setLiveUnitData] = useState([])  // this is firestore/Units
  const [unitMap, setUnitMap] = useState([])  // this is tc_map.csv
  const [notes, setNotes] = useState([])  // this is firestore/Notes
  const [turnoverData, setTurnoverData] = useState([])  // this is mat_data.csv

  const [selectedUnit, setSelectedUnit] = useState(null) // <number>
  const [otherUnits, setOtherUnits] = useState([]) // All the other units that fall under the same "trading as" as the selected unit, <number[]>
  const [isHighlightingUnits, setIsHighlightingUnits] = useState(false) // Determines whether to lower the opacity of non-selected units

  const [floor, setFloor] = useState(0)  // null = all floors
  const [dataOverlay, setDataOverlay] = useState("category") // "category" | "ocr"

  const [comparisonUnits, setComparisonUnits] = useState([]) // <number[]>

  const [errors, setErrors] = useState([])  // <>

  // determine the default selected month and year for MAT Turnover calcs - this gets overriden when MAT data loads
  // note: Date.getMonth() returns month number - 1 (i.e. Jan is 0), therefore last month is (this value + 12)%12
  const lastMonth = ((new Date()).getMonth() + 12) % 12
  // default to current year, or last year if it's January (last month is December)
  const [selectedYear, setSelectedYear] = useState((new Date()).getFullYear() + (lastMonth === 12 ? -1 : 0))
  const [selectedMonth, setSelectedMonth] = useState(lastMonth)  // default to last month

  const handleError = useCallback(error => {
    setErrors(prevState => [error, ...prevState.filter(e => e.message !== error.message)]);
    logEvent({ eventName: "error", error: error.toString() })
    console.error(error)
  }, [])

  const handleSelectUnit = (unitId) => {
    setIsHighlightingUnits(false)
    if (unitId === null) {
      setSelectedUnit(null)
      setOtherUnits([])
      return
    }
    setSelectedUnit(prevState => {
      if (prevState !== unitId) {
        findOtherUnits(unitId);
        return unitId
      } else {
        setOtherUnits([])
        return null  // deselect
      }
    });
  }

  const findOtherUnits = (selectedUnitId) => {
    const unitData = units.find(
      (d) => selectedUnitId === d.unit_id
    );
    if (!unitData || !unitData?.trading_as) {
      // NB: Vacant units that appear on the map as being operated by The Trafford Centre Ltd.
      // have a falsy trading_as property (""), so clicking one will not cause the others to be highlighted. 
      setOtherUnits([]);
      return
    }
    const unitsWithSameTradingAs = units.filter((u) => u.trading_as === unitData.trading_as && u.unit_id !== selectedUnitId);
    setOtherUnits(unitsWithSameTradingAs.map((u) => u.unit_id));
  }

  const handleClickUnit = (unitId) => {
    handleSelectUnit(unitId);
  }

  const handleClickCompareUnit = useCallback((unitId, isComparingOtherUnits = false) => {
    if (unitId === null) {
      return
    }
    // if removing the current analysis unit, set analysis unit to null
    if (unitId === selectedUnit && comparisonUnits.indexOf(unitId) !== -1 && !isComparingOtherUnits) {
      console.log("Deselect", unitId, "for analysis")
      setTimeout(() => setSelectedUnit(null), 0)
    }
    setComparisonUnits(prevState => {
      if (prevState.indexOf(unitId) === -1) {
        return [unitId, ...prevState]
      } else {
        return [...prevState.filter(d => d !== unitId)]  // remove from compare
      }
    })

    logEvent({ eventName: "compareUnit", unitId })

  }, [selectedUnit, comparisonUnits])

  const handleClickCompareOtherUnits = useCallback((otherUnits) => {
  if (!Array.isArray(otherUnits) || otherUnits.length === 0) {
    return;
  }

  otherUnits.forEach((unitId) => {
    handleClickCompareUnit(unitId, true);

    logEvent({ eventName: "compareUnits", otherUnits });
  });
}, [selectedUnit, comparisonUnits]);

  const handleSearchSelectUnit = (e, unit) => {
    if (typeof(unit) === "string") {
      handleSearchSelectCategory(e, unit, false)
      return
    }

    // on the unit comparison page, clicking a unit adds it to the comparison
    if (page === "comparison") {
      if (unit) handleClickCompareUnit(unit.unit_id);
      return
    }

    handleSelectUnit(unit?.unit_id || null); 
  }

  const handleSearchSelectCategory = (e, category, remove) => {
    setComparisonUnits(prevState => {
      if (remove) {
        const otherUnitsInCategory = units.filter(d => d.category === category).map(d => d.unit_id)
        return [...prevState.filter(d => otherUnitsInCategory.indexOf(d) === -1)]
      } else {
        // add all from the category as long as they aren't already selected
        return [...prevState, ...units.filter(d => d.category === category && prevState.indexOf(d.unit_id) === -1).map(d => d.unit_id)]
      }
    })
  }

  const handleEditUnit = (unit_document_id, unitId, updatedFields) => {
    post("/units", {unit_document_id, unitId, ...updatedFields}, idToken, updatedData => {

      // update data in app state
      setLiveUnitData(prevState => [...prevState.filter(d => d.unitId !== unitId), updatedData])

    }, handleError)
  }

  function fetchUser() {
    return get("/user", idToken, d => {
      setIsVerifiedUser(d.verified)
      setUserName(d.full_name)
    }, handleError)
  }

  function fetchLeasingSchedule() {
    return getCSV("/data/leasing_schedule", idToken, d => {
      console.log(`Loaded leasing schedule with ${d.length} rows.`)
      setUnits(d.map(cleanLeasingScheduleRow))
    }, handleError)
  }

  function fetchUnits() {
    return get("/units", idToken, data => {
      console.log("Got live data for", data.length, "units:", data)
      setLiveUnitData(data)
    }, handleError)
  }

  function fetchLiveUnitData() {
    // get 'live' unit data from firestore
    return getCSV("/data/mat", idToken, data => {
      // parse data and determine the month of the latest data at the same time
      let latestDataMonth = 0;
      let latestDataYear = 2021;
      let latestDataMonthYear = 0;
      console.log("Got MAT data with", data.length, "rows:", data)
      setTurnoverData(data.map(d => {

        let turnover = parseInt(d.turnover)
        // convert null or NaN turnover to 0 so that we can calculate totals for partial data (there's a lot of these)
        if (!turnover) {
          turnover = 0;
        }
        const year = parseInt(d.year)
        const month = parseInt(d.month)

        // if turnover data exists then record the latest date seen
        if (turnover !== 0 && ((year - 2021)*12 + month) > latestDataMonthYear) {
          latestDataMonthYear = (year - 2021)*12 + month
          latestDataYear = year
          latestDataMonth = month
        }

        return {
          unit_id: parseInt(d.unit_id),
          turnover: turnover,
          year: year,
          month: parseInt(d.month),
        }
      }))

      // reset the selected month and year: pick the latest month where there is some data for some units
      setSelectedMonth(latestDataMonth)
      setSelectedYear(latestDataYear)
    }, handleError)
  }

  function fetchMapData() {
    return getCSV("/data/tc_map", idToken, rawData => {
      console.log(`Loaded unit map with ${rawData.length} rows.`)
      // aggregate data to one row per unit
      setUnitMap(rawData.map(cleanMapRow))
    }, handleError)
  }

  function fetchNotes() {
    return get("/notes", idToken,d => {
      console.log("Got all notes:", d)
      setNotes(d)
    }, handleError)
  }

  async function fetchData() {
    await fetchUser();
    await fetchLeasingSchedule();
    await fetchUnits();
    await fetchLiveUnitData();
    await fetchMapData();
    await fetchNotes();
    logEvent({ eventName: "dataLoaded" })
  }

  // get data when app loads / when user signs in
  useEffect(() => { 
    if (idToken && isSignedIn) {
      fetchData();
    }
  }, [isSignedIn, idToken])

  // perform join on the units data and the live units data
  useEffect(() => {
    console.log("New data has been loaded - reprocessing data")

    // join live data to leasing schedule data
    setUnits(prevState => {
      const newState = prevState.map(left => ({
        ...left,
        ...liveUnitData.find(d => left.unit_id === d.unitId)
      }))
      console.log("New joined data for selected unit:", newState.find(d => d.unit_id === selectedUnit))
      return [...newState]
    })

  }, [liveUnitData])

  /**
   * Replace all note data for one unit with fresh data from the API.
   * Idempotent state update that runs every time notes are created/edited/deleted.
   * @param unitId
   */
  const handleRefreshNotesForUnit = (unitId) => {
      get(`/notes/${unitId}`, idToken, newNotes => {
        setNotes(prevState => {
          return [...prevState.filter(note => note.unitId !== unitId), ...newNotes]
        })
      }, handleError)
  }

  // handle changing page, overlay, or internal/external
  useEffect(() => {
    const r = document.querySelector(':root');
    r.style.setProperty('--themeColour', isInternal ? 'var(--black)' : 'var(--red)');

    // if we've switched to the external view then make sure an external overlay is selected
    if (!isInternal && (dataOverlay !== "category" && dataOverlay !== "none")) {
      setDataOverlay("category")
    }

    // if we've switched to the external view then make sure the map page is selected
    if (!isInternal && page !== "map") {
      setPage("map")
    }
  }, [isInternal, dataOverlay, page])

  // check that the selected unit's floor is showing - always show the floor that the selected unit is on
  useEffect(() => {
    if (!selectedUnit) return;
    if (floor === null) return;  // if already showing both floors - do nothing
    if (!units) return;

    // We use filter because a unit could be spread over two floors
    // (and therefore have > 1 map instance)
    const unitData = unitMap.filter(d => selectedUnit === d.unit_id);

    if (unitData.length !== 1) {
      setFloor(null)  // if unit is across two floors (or not on the map), show both
      return;
    }

    setFloor(unitData[0].floor_id)
  }, [selectedUnit])

  // find units that have no matching unit on the map
  // const allMapUnitIds = unitMap.map(d => d.unit_id)
  // const unmappedUnits = units.filter(unit => {
  //   return allMapUnitIds.indexOf(unit.unit_id) === -1
  // })
  const unmappedUnits = []

  return (
    <div className="App" style={{ width: "100vw", height: "100vh", overflow: "hidden" }}>
      <Stack direction={"column"} style={{ width: "100%", height: "100%" }} >

        <AppBar
          isSignedIn={isSignedIn}
          isInternal={isInternal}
          setIsInternal={setIsInternal}
          userName={userName}
          page={page}
          setPage={setPage}
          comparisonUnits={comparisonUnits}
        />

        <NotificationCenter errors={errors} />

        <SignInScreen handleSignInOut={setIsSignedIn} />

        {isSignedIn && (page === "map") && (
          <UnitMap
            isInternal={isInternal}
            isVerifiedUser={isVerifiedUser}
            units={units}
            selectedUnit={selectedUnit}
            otherUnits={otherUnits}
            unitMap={unitMap}
            handleClickUnit={handleClickUnit}
            handleClickCompareUnit={handleClickCompareUnit}
            handleEditUnit={handleEditUnit}
            handleError={handleError}
            handleSearchSelectUnit={handleSearchSelectUnit}
            idToken={idToken}
            floor={floor}
            setFloor={setFloor}
            selectedMonth={selectedMonth}
            selectedYear={selectedYear}
            setSelectedMonth={setSelectedMonth}
            setSelectedYear={setSelectedYear}
            turnoverData={turnoverData}
            dataOverlay={dataOverlay}
            setDataOverlay={setDataOverlay}
            notes={notes}
            handleRefreshNotesForUnit={handleRefreshNotesForUnit}
            comparisonUnits={comparisonUnits}
            handleClickCompareOtherUnits ={handleClickCompareOtherUnits}
            isHighlightingUnits={isHighlightingUnits}
            setIsHighlightingUnits={setIsHighlightingUnits}
          />
        )}
        {isSignedIn && (page === "comparison") && (
          <UnitComparison
            units={units}
            handleSearchSelectUnit={handleSearchSelectUnit}
            handleClickCompareUnit={handleClickCompareUnit}
            comparisonUnits={comparisonUnits}
            handleSearchSelectCategory={handleSearchSelectCategory}
            selectedUnit={selectedUnit}
            handleClickUnit={handleClickUnit}
            setComparisonUnits={setComparisonUnits}
            isVerifiedUser={isVerifiedUser}
          />
        )}
      </Stack>
    </div>
  );
}

export default App;
