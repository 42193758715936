import {useEffect, useState} from "react";
import Stack from "@mui/material/Stack";
import "./SearchToggle.css";
import {logEvent} from "../../js/interface";


const SearchToggle = ({ children }) => {

  const [open, setOpen] = useState(false)

  return (
    <Stack direction={"row-reverse"} spacing={1} style={{position: "absolute", top: 20, right: 20}}>

      <div className={"Search-icon"} onClick={() => {
        if (!open) logEvent({ eventName: "openSearch" });
        setOpen(!open)

      }}>
        {open ? (
          <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z"/>
          </svg>
        ) : (
          <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M12.5 11H11.71L11.43 10.73C12.41 9.59 13 8.11 13 6.5C13 2.91 10.09 0 6.5 0C2.91 0 0 2.91 0 6.5C0 10.09 2.91 13 6.5 13C8.11 13 9.59 12.41 10.73 11.43L11 11.71V12.5L16 17.49L17.49 16L12.5 11ZM6.5 11C4.01 11 2 8.99 2 6.5C2 4.01 4.01 2 6.5 2C8.99 2 11 4.01 11 6.5C11 8.99 8.99 11 6.5 11Z"/>
          </svg>
        )}
      </div>

      {open && (
        <>
          {children}
        </>
      )}

    </Stack>
  );

}

export default SearchToggle;
