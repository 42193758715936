import Stack from "@mui/material/Stack";
import { TabProps } from "./TabGroup";
import Typography from "@mui/material/Typography";
import OpenInNew from "@mui/icons-material/OpenInNew";
import { colours } from "../../../theme";
import { formatUnitId } from "../../../js/format";

interface DocsTabProps extends TabProps {
  //
}

export default function DocsTab(props: DocsTabProps) {
  return (
    <Stack spacing={2}>
      <Typography>Unit Documentation</Typography>
      <Stack direction={"row"} spacing={1} alignItems={"center"}>
        <OpenInNew />
        <a
          href={`https://pradera.egnyte.com/fl/LP3R2mMVMS#folder-link/Upper%20Mall/${formatUnitId(
            props.selectedUnit
          )}`}
          target="_blank"
          rel="noreferrer"
          style={{ color: colours.black }}
        >
          Unit Photographs
        </a>
      </Stack>
    </Stack>
  );
}
