import firebase from "firebase/compat/app";
import { useEffect, useState } from "react";

/**
 * Custom hook that returns the firebase id token, and the current user
 * (if the user is signed in).
 * 
 * @param isSignedIn 
 * @returns The current user's id token, or an empty string.
 */
export default function useFirebaseAuth(isSignedIn?: boolean): FirebaseAuth {
  const [token, setToken] = useState("");
  const [user, setUser] = useState<firebase.User | null>(null);

  useEffect(() => {
    async function fetchUser() {
      const currentUser = firebase.auth()?.currentUser || null;
      const idToken = await currentUser?.getIdToken() || '';
      setToken(idToken);
      setUser(currentUser)
    }
    // Initial fetch user call is required.
    fetchUser();
    // refresh the token every 15 minutes
    const interval = setInterval(() => {
      console.log("Refreshing token")
      fetchUser();
    }, 1000 * 60 * 15)

    return () => {
      clearInterval(interval)
    }
  }, [isSignedIn]);

  return { token, user };
}

export interface FirebaseAuth {
  token: string
  user: firebase.User | null
}
