import * as React from 'react';
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';
import {ContactSupport, Info} from "@mui/icons-material";
import firebase from 'firebase/compat/app';
import "./Profile.css"

const ProfileIcon = () => {
  return (
    <svg className={"Profile-icon"} width="48" height="48" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="15" cy="14" r="9" />
      <g clipPath="url(#clip0_2258_19074)">
        <path d="M14.9999 8C16.9299 8 18.4999 9.57 18.4999 11.5C18.4999 13.43 16.9299 15 14.9999 15C13.0699 15 11.4999 13.43 11.4999 11.5C11.4999 9.57 13.0699 8 14.9999 8ZM14.9999 22C12.9699 22 10.5699 21.18 8.85986 19.12C10.5499 17.8 12.6799 17 14.9999 17C17.3199 17 19.4499 17.8 21.1399 19.12C19.4299 21.18 17.0299 22 14.9999 22Z" fill="black"/>
      </g>
      <defs>
        <clipPath id="clip0_2258_19074">
          <rect width="30" height="30" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  )
}

export default function Profile({ userName }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const user = firebase.auth().currentUser
  if (!user) return null;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <React.Fragment>
      <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
        <Tooltip title="Account settings">
          <IconButton
            onClick={handleClick}
            size="small"
            // sx={{ ml: 2 }}
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
          >
            <ProfileIcon />
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem style={{ cursor: "unset" }}>
          <Typography><b>{userName ?? user.email}</b></Typography>
        </MenuItem>
        <MenuItem style={{ cursor: "unset" }}>
          <Typography>Leasing Manager</Typography>
        </MenuItem>
        {/*<MenuItem onClick={handleClose}>*/}
        {/*  <Avatar /> Profile*/}
        {/*</MenuItem>*/}
        {/*<MenuItem onClick={handleClose}>*/}
        {/*  <Avatar /> My account*/}
        {/*</MenuItem>*/}
        <Divider />
        {/*<MenuItem onClick={handleClose}>*/}
        {/*  <ListItemIcon>*/}
        {/*    <Info color={"secondary"} fontSize="small" />*/}
        {/*  </ListItemIcon>*/}
        {/*  About*/}
        {/*</MenuItem>*/}
        {/*<MenuItem onClick={handleClose}>*/}
        {/*  <ListItemIcon>*/}
        {/*    <Settings color={"secondary"} fontSize="small" />*/}
        {/*  </ListItemIcon>*/}
        {/*  Settings*/}
        {/*</MenuItem>*/}
        <MenuItem onClick={() => {
          window.open("https://datasparq.atlassian.net/servicedesk/customer/portals", "_blank");
        }}>
          <ListItemIcon>
            <ContactSupport color={"secondary"} fontSize="small" />
          </ListItemIcon>
          Support Portal
        </MenuItem>
        <MenuItem onClick={() => {
          firebase.auth().signOut()
          handleClose()
        }}>
          <ListItemIcon>
            <Logout color={"secondary"} fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
}

