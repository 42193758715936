import {Stack, Typography} from "@mui/material";
import {colours} from "../../theme";
import "./ButtonSmall.css";

const ButtonSmall = ({ onClick, label, filled, fixedWidth = 0 }) => {
  return (
    <Stack className={"ButtonSmall" + (filled ? " ButtonSmall-filled" : "")} onClick={onClick} sx={{ width: fixedWidth || "auto", textAlign: fixedWidth ? "center" : "auto" }}>
      <Typography>{label}</Typography>
    </Stack>
  )
}

export default ButtonSmall;
