import * as React from "react";
import {Stack} from "@mui/material";
import "./FloorSelect.css"

const FloorSelect = ({ floor, setFloor }) => {

  return (
    <Stack className={"FloorSelect"} direction={"column"} spacing={"1px"} style={{ position: "absolute", top: 80, right: 20 }} >
      <div className={`FloorSelect-button FloorSelect-levelFive ${floor === 5 ? "FloorSelect-selected" : ""}`} onClick={() => setFloor(5)}>
        <svg width="16" height="11" viewBox="0 0 16 11" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M0.296509 10.1281V0.32814H2.67651V8.02814H7.04451V10.1281H0.296509Z"/>
          <path d="M11.5438 10.2261C10.8064 10.2261 10.1344 10.0768 9.52776 9.77814C8.93043 9.47947 8.43576 9.05014 8.04376 8.49014L9.31776 7.09014C9.70043 7.51014 10.0551 7.79947 10.3818 7.95814C10.7084 8.10747 11.0724 8.18214 11.4738 8.18214C11.7724 8.18214 12.0384 8.12147 12.2718 8.00014C12.5051 7.87881 12.6871 7.71081 12.8178 7.49614C12.9578 7.27214 13.0278 7.02481 13.0278 6.75414C13.0278 6.47414 12.9578 6.22681 12.8178 6.01214C12.6871 5.78814 12.5051 5.61081 12.2718 5.48014C12.0478 5.34947 11.7864 5.28414 11.4878 5.28414C11.2731 5.28414 11.0724 5.30281 10.8858 5.34014C10.6991 5.37747 10.4984 5.44281 10.2838 5.53614C10.0784 5.62014 9.83109 5.74614 9.54176 5.91414L8.53376 4.57014L9.13576 0.244141H14.7638V2.24614H10.2698L10.8858 1.46214L10.4238 4.57014L9.58376 4.29014C9.76109 4.13147 9.97576 3.99147 10.2278 3.87014C10.4891 3.74881 10.7691 3.65081 11.0678 3.57614C11.3664 3.49214 11.6651 3.45014 11.9638 3.45014C12.5798 3.45014 13.1304 3.59481 13.6158 3.88414C14.1104 4.16414 14.5024 4.55614 14.7918 5.06014C15.0811 5.56414 15.2258 6.14281 15.2258 6.79614C15.2258 7.44014 15.0624 8.02347 14.7358 8.54614C14.4091 9.05947 13.9704 9.47014 13.4198 9.77814C12.8691 10.0768 12.2438 10.2261 11.5438 10.2261Z"/>
        </svg>
      </div>
      <div className={`FloorSelect-button FloorSelect-upperFloor ${floor === 1 ? "FloorSelect-selected" : ""}`} onClick={() => setFloor(1)}>
        <svg width="18" height="10" viewBox="0 0 18 10" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M4.2 9.884C3.388 9.884 2.66467 9.72067 2.03 9.394C1.39533 9.058 0.896 8.60067 0.532 8.022C0.177333 7.44333 0 6.78067 0 6.034V0H2.408V5.908C2.408 6.26267 2.48733 6.58467 2.646 6.874C2.80467 7.154 3.01933 7.378 3.29 7.546C3.56067 7.70467 3.864 7.784 4.2 7.784C4.55467 7.784 4.86733 7.70467 5.138 7.546C5.418 7.378 5.642 7.154 5.81 6.874C5.978 6.58467 6.062 6.26267 6.062 5.908V0H8.386V6.034C8.386 6.78067 8.204 7.44333 7.84 8.022C7.48533 8.60067 6.99067 9.058 6.356 9.394C5.72133 9.72067 5.00267 9.884 4.2 9.884Z"/>
          <path d="M10.7751 9.8V0H13.1551V9.8H10.7751ZM11.8811 6.048V4.06H16.9491V6.048H11.8811ZM11.8811 2.1V0H17.5231V2.1H11.8811Z"/>
        </svg>
      </div>
      <div className={`FloorSelect-button FloorSelect-lowerFloor ${floor === 0 ? "FloorSelect-selected" : ""}`} onClick={() => setFloor(0)}>
        <svg width="16" height="10" viewBox="0 0 16 10" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M0 9.8V0H2.38V7.7H6.748V9.8H0Z"/>
          <path d="M8.53125 9.8V0H10.9113V9.8H8.53125ZM9.63725 6.048V4.06H14.7052V6.048H9.63725ZM9.63725 2.1V0H15.2793V2.1H9.63725Z"/>
        </svg>
      </div>
    </Stack>
  )
}

export default FloorSelect;
