import {useMemo} from "react";
import {Stack, Typography} from "@mui/material";
import {colours} from "../../theme"
import * as colourUtils from "../../js/colourUtils";
import {formatCurrency, formatInt, formatPercent, formatDate, NA, formatUnitId, demiseToCode, formatMonth} from "../../js/format";

const Tooltip = ({ hidden, unitData, x, y, dataOverlay, note }) => {

  // map of label to values
  let overlayValues = useMemo(() => {
    return unitData ? dataOverlayValues(unitData, dataOverlay) : null;
  }, [unitData, dataOverlay])

  if (hidden) return;

  // tooltip needs to be wider for turnover overlays
  const width = Object.keys(colourUtils.turnoverLabeltoColourMap).indexOf(dataOverlay) === -1 ? 300 : 450;

  return (
    <Stack direction={"column"} spacing={1} style={{ position: "absolute", top: y - 70, left: x - 220 + 20, borderRadius: 20, background: colours.white, padding: 15, filter: "var(--shadow)", zIndex: 999, maxWidth: 500 }}>
      {unitData ?
      <>
        <Typography variant={"h4"}>{unitData.trading_as !== "" ? unitData.trading_as : unitData.tenant}</Typography>
        <div style={{ minWidth: width, display: "grid", gridTemplateColumns: "max-content max-content", columnGap: 10 }}>
          <TooltipRow label={`Unit ${formatUnitId(unitData.parsed_unit_id)}`} value={demiseToCode(unitData.demise)} />
          {Object.keys(overlayValues).map(label => <TooltipRow key={label} label={label} value={overlayValues[label]} />)}
        </div>
      </>
     : <Typography>No Data</Typography>}
      {note && <hr style={{ border: "none", borderTop: "1px dashed var(--grey4)", }} />}
      {note && <Typography style={{color: colours.red}}>{note.body}</Typography>}
    </Stack>
  )
}

const dataOverlayValues = (unitData, dataOverlay) => {
  // console.log("dataOverlayValues", unitData, dataOverlay)
  let value;
  const values = {};

  switch (dataOverlay) {
    case "category":
      return {[colourUtils.dataOverlayToLabelMap[dataOverlay]]: unitData.category};

    case "leasing_status":
      return {"Status": unitData.leasingStatus === "" ? NA : unitData.leasingStatus};

    case "turnover":
      return {[colourUtils.dataOverlayToLabelMap[dataOverlay]]: formatCurrency(unitData.last_year_turnover)}

    case "sales_density":
      if (!unitData.last_year_turnover) {
        value = "No Data";
      } else if (!unitData.total_area || unitData.total_area === 0) {
        value = "No Data"
      } else {
        const sales_density = unitData.last_year_turnover / unitData.total_area
        if (sales_density === null) {
          value = NA
        } else {
          value = `£${formatInt(sales_density)} per sqft`
        }
      }

      return {[colourUtils.dataOverlayToLabelMap[dataOverlay]]: value}

    case "rsr":
      return {[colourUtils.dataOverlayToLabelMap[dataOverlay]]: formatPercent(unitData.rent_to_sales_ratio)}

    case "ocr":
      return {[colourUtils.dataOverlayToLabelMap[dataOverlay]]: formatPercent(unitData.occupancy_cost_ratio)}

    case "lease_expiry":
      return {
        [colourUtils.dataOverlayToLabelMap[dataOverlay]]: formatDate(unitData.end_date),
        "1954 act protected": unitData.outside1954_act === "true" ? "no" : unitData.outside1954_act === "false" ? "yes" : NA
      }

    case "break_option":
      // let breakOption;
      // if (unitData.break_option_type.toLowerCase() === "brr") {
      //   breakOption = "Rolling";
      // } else if (unitData.break_option === null || typeof (unitData.break_option) === "undefined") {
      //   breakOption = "No Data";
      // }
      values[colourUtils.dataOverlayToLabelMap[dataOverlay]] = formatDate(unitData.break_option)

      if (!(unitData.break_option === null || typeof(unitData.break_option) === "undefined" || unitData.break_option === "")) {
        values["Notice Date"] = formatDate(unitData.break_notice_date)
      }

      if (unitData.break_option_type.toLowerCase() === "brr") {
        values["Type"] = "Rolling"
      } else if (unitData.break_option_type) {
        values["Type"] = unitData.break_option_type
      }

      return values

    case "mat_vs_category":
      if (unitData.turnoverValues && unitData.turnoverValues.last12MonthsMAT) {

        if (!unitData.turnoverValues.last12MonthsMAT) {
          return {}
        } else if (!unitData.turnoverValues.MATGrowth) {
          return  {
            "MAT Turnover:": "",
            "· Last year": formatCurrency(unitData.turnoverValues.last12MonthsMAT),
          }
        } else {
          return {
            "MAT Turnover:": "",
            "· Last year": formatCurrency(unitData.turnoverValues.last12MonthsMAT),
            "· Previous year": formatCurrency(unitData.turnoverValues.previous12MonthsMAT),
            "· Growth": formatPercent(unitData.turnoverValues.MATGrowth),
            ["· " + unitData.category + " avg"]: unitData.turnoverValuesCategories ? formatPercent(unitData.turnoverValuesCategories.MATGrowth) : NA,
            "· Difference": unitData.turnoverValues.MATGrowth && unitData.turnoverValuesCategories ? formatPercent(unitData.turnoverValues.MATGrowth - unitData.turnoverValuesCategories.MATGrowth) : NA,
          }
        }
      }
      return {
      }

    case "mat_vs_category_monthly":
      if (unitData.turnoverValues && unitData.turnoverValues.MATGrowthMonthly) {
        return {
          "Monthly Turnover:": "",
          [`. ${formatMonth(unitData.turnoverValues.selectedMonth)} ${unitData.turnoverValues.selectedYear}`]: formatCurrency(unitData.turnoverValues.selectedMonthTurnover),
          [`. ${formatMonth(unitData.turnoverValues.selectedMonth)} ${unitData.turnoverValues.selectedYear - 1}`]: formatCurrency(unitData.turnoverValues.selectedMonthPreviousYearTurnover),
          "· Growth": formatPercent(unitData.turnoverValues.MATGrowthMonthly),
          ["· " + unitData.category + " avg"]: unitData.turnoverValuesCategories ? formatPercent(unitData.turnoverValuesCategories.MATGrowthMonthly) : NA,
          "· Difference": unitData.turnoverValues.MATGrowthMonthly && unitData.turnoverValuesCategories ? formatPercent(unitData.turnoverValues.MATGrowthMonthly - unitData.turnoverValuesCategories.MATGrowthMonthly) : NA,
        }
      } else {
        return {}
      }

    case "mat_growth":
      if (unitData.turnoverValues) {

        if (!unitData.turnoverValues.last12MonthsMAT) {
          return {}
        } else if (!unitData.turnoverValues.MATGrowth) {
          return  {
            "MAT Turnover:": "",
            "· Last year": formatCurrency(unitData.turnoverValues.last12MonthsMAT),
          }
        } else {
          return {
            "MAT Turnover:": "",
            "· Last year": formatCurrency(unitData.turnoverValues.last12MonthsMAT),
            "· Previous year": formatCurrency(unitData.turnoverValues.previous12MonthsMAT),
            "· Growth": formatPercent(unitData.turnoverValues.MATGrowth),
          }
        }
      }
      return {}

    case "mat_growth_monthly":
      if (unitData.turnoverValues && unitData.turnoverValues.MATGrowthMonthly) {
        return {
          "Monthly Turnover:": "",
          [`. ${formatMonth(unitData.turnoverValues.selectedMonth)} ${unitData.turnoverValues.selectedYear}`]: formatCurrency(unitData.turnoverValues.selectedMonthTurnover),
          [`. ${formatMonth(unitData.turnoverValues.selectedMonth)} ${unitData.turnoverValues.selectedYear - 1}`]: formatCurrency(unitData.turnoverValues.selectedMonthPreviousYearTurnover),
          "· Growth": formatPercent(unitData.turnoverValues.MATGrowthMonthly),
        }
      } else {
        return {}
      }

    case "mat_density_monthly":
      if (unitData.turnoverValues) {
        if (!unitData.turnoverValues.selectedMonthTurnover) {
          return {}
        } else if (!unitData.total_area || unitData.total_area === 0) {
          return {}
        } else {
          return {
            [`${formatMonth(unitData.turnoverValues.selectedMonth)} ${unitData.turnoverValues.selectedYear} turnover`] : formatCurrency(unitData.turnoverValues.selectedMonthTurnover),
            "Total area": formatInt(unitData.total_area),
            "MAT density": `£${formatInt(unitData.turnoverValues.selectedMonthTurnover / unitData.total_area)} per sqft`
          }
        }
      } else {
        return {}
      }

    case "mat_density":
      if (unitData.turnoverValues) {
        if (!unitData.turnoverValues.last12MonthsMAT) {
          return {}
        } else if (!unitData.total_area || unitData.total_area === 0) {
          return {}
        } else {
          return {
            "Last year MAT turnover": formatCurrency(unitData.turnoverValues.last12MonthsMAT),
            "Total area": formatInt(unitData.total_area),
            "MAT density": `£${formatInt(unitData.turnoverValues.last12MonthsMAT / unitData.total_area)} per sqft`
          }
        }
      } else {
        return {}
      }


    case "mat_ocr":
      if (unitData.turnoverValues && unitData.turnoverValues.last12MonthsMAT) {
        return {
          "Last year MAT turnover": formatCurrency(unitData.turnoverValues.last12MonthsMAT),
          "Total Occupational Costs": formatCurrency(unitData.total_occupational_costs),
          "MAT-based OCR": unitData.turnoverValues.last12MonthsMAT ? formatPercent(unitData.total_occupational_costs / unitData.turnoverValues.last12MonthsMAT) : NA
        }
      } else {
        return {}
      }


    default:
      return {}
  }
}

const TooltipRow = ({label, value}) => {
  return (
    <>
      <Typography style={{ gridColumn: 1 }}>{label}</Typography>
      <Typography style={{ gridColumn: 2 }}><b>{value}</b></Typography>
    </>
  )
}

export default Tooltip;
