import {useState} from "react";
import {Typography} from "@mui/material";
import {colours} from "../../theme";
import {formatDate} from "../../js/format";
import {Star, Edit, Archive, Delete, Undo} from "@mui/icons-material";
import useFirebaseAuth from "../../hooks/useFirebaseAuth";
import {delete_, post} from "../../js/interface";
import { SimpleTextArea } from "../TextArea/TextArea";
import Button from "../Button/Button";
import "./Note.css";

/**
 *
 * @param id
 * @param body
 * @param unitId
 * @param createdBy
 * @param createdByName
 * @param editedBy
 * @param archived
 * @param created
 * @param modified
 * @param highPriority
 * @param handleRefreshNotesForUnit
 * @param handleError
 * @param isVerifiedUser
 * @param priorityNote The current priority note for this unit, if one exists. We use this to ensure that only one note is prioritised at any time.
 */
const Note = ({ id, body, unitId, createdBy, createdByName, editedBy, archived, created, modified, highPriority, handleRefreshNotesForUnit, handleError, isVerifiedUser, priorityNote }) => {

  const [editMode, setEditMode] = useState(false)
  const idToken = useFirebaseAuth().token // Firebase id token.

  const handleDeleteNote = (e) => {
    console.log("Archiving note")
    delete_(`/notes/${id}`, idToken, res => {
      handleRefreshNotesForUnit(unitId)
    }, handleError)
  }

  /**
   * Archive or un-archive a note
   * @param e
   */
  const handleArchiveNote = (e) => {
    console.log("Archiving note")
    post("/notes/archive", { id, archived: !archived }, idToken, res => {
      handleRefreshNotesForUnit(unitId)
    }, handleError)
  }

  const handlePrioritiseNote = (e) => {
    let promise;
    // if there is already a priority note, and we are prioritising a new note, deprioritise it
    if (priorityNote && !highPriority) {
      console.log("Deprioritising existing note with ID:", priorityNote.id)
      promise = post("/notes/prioritise", { id: priorityNote.id, highPriority: false }, idToken, res => {})
    } else {
      promise = Promise.resolve()
    }

    console.log(highPriority ? "Deprioritising note" : "Prioritising note", "with ID:", id)
    promise.then(() => {
      post("/notes/prioritise", { id, highPriority: !highPriority }, idToken, res => {
        handleRefreshNotesForUnit(unitId)
      }, handleError)
    })
  }

  /**
   * Save edits to the note body
   * @param event
   */
  const handleEditNote = (event) => {
    event.preventDefault()
    const form = event.target
    const formData = new FormData(form);

    const formJson = Object.fromEntries(formData.entries());
    console.log(formJson)

    console.log("Saving edited note body")
    post("/notes/update", { id, body: formJson.noteBody }, idToken, res => {
      handleRefreshNotesForUnit(unitId)
      setEditMode(false)
    }, handleError)
  }


  return (
    <div className={"Note" + ((highPriority && !archived) ? " Note-highPriority" : "") + (editMode ? " Note-editMode" : "")}>
      {editMode ?
        <form id={"note-editor"} className={"Note-editor"}
              onSubmit={(e) => handleEditNote(e)}>
          <SimpleTextArea id={"note-editor-textarea"} label={"noteBody"} name={"noteBody"} defaultValue={body} />
          <div className={"Note-editor-buttons"}>
            <Button variant={"outline"} label={"Cancel"} onClick={() => setEditMode(false)} />
            <Button label={"Save"} type={"submit"} />
          </div>
        </form>
        : <>
          <Typography style={{ color: (highPriority && !archived) ? colours.red : colours.black }}>{body}</Typography>
          <div className={"Note-info"}>
            <Typography style={{ color: colours.grey2 }}>{createdByName ?? createdBy}</Typography>
            <Typography style={{ color: colours.grey2 }}>{formatDate(created)}{(editedBy !== "" && createdBy !== editedBy) && " · edited " + formatDate(modified)}</Typography>
          </div>
          {(highPriority && !archived) && <Star className={"Note-highPriorityIcon"} />}
          {isVerifiedUser &&
            <div className={"Note-menu"}>
              {!archived && <Star onClick={handlePrioritiseNote}/>}
              {!archived && <Edit onClick={() => setEditMode(!editMode)} />}
              {archived ? <Undo onClick={handleArchiveNote} /> : <Archive onClick={handleArchiveNote} />}
              {archived && <Delete onClick={handleDeleteNote} />}
            </div>
          }
          </>
      }

    </div>
  )
}

export default Note;
