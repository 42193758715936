import {useState} from "react";
import {Alert, AlertColor, IconButton} from "@mui/material";

interface NotificationProps {
  message: string;
  severity: AlertColor;
  onClose: () => void;

}


/**
 * Notifications are push notifications. They can be dismissed.
 */
const Notification = ({ message, severity, onClose }: NotificationProps) => {

  return (
    <Alert
      severity={severity}
      sx={{ mb: 1 }}
      action={
        <IconButton aria-label="close" color="inherit" size="small" onClick={onClose}>
          <span style={{ fontFamily: "Material Icons" }}>close</span>
        </IconButton>
      }>
      {message}
    </Alert>
  )
}

export default Notification;
