import Stack from "@mui/material/Stack";
import { TabProps } from "./TabGroup";
import OtherUnitsTable from "../../OtherUnitsTable/OtherUnitsTable";
import Box from "@mui/material/Box";
import ButtonSmall from "../../ButtonSmall/ButtonSmall";
import { useEffect, useState } from "react";

interface OtherUnitsTabProps extends TabProps {
  //
}

export default function OtherUnitsTab(props: OtherUnitsTabProps) {
  const [isComparing, setIsComparing] = useState(false);

  useEffect(() => {
    if (props.unitData && props.otherUnitsData?.length) {
      const allUnitsData = [...props.otherUnitsData, props.unitData];
      const unitIds = allUnitsData.map((unit) => unit.unit_id);
      const isComparingUnits = unitIds.every(
        (id) => !!props.comparisonUnits.find((cu) => cu === id)
      );
      setIsComparing(isComparingUnits);
    }
  }, [props.comparisonUnits, props.otherUnitsData, props.unitData]);

  const compareOtherUnits = () => {
    const allUnitsData = [...props.otherUnitsData, props.unitData];
    const unitIds = allUnitsData.map((unit) => unit.unit_id);
    // Individual IDs can be deselected on the Unit Comparison page.
    // Therefore we need to add all that are missing (in most cases this will be all).
    const missingIds = unitIds.filter(
      (id) => !props.comparisonUnits.some((cu) => cu === id)
    );
    props.handleClickCompareOtherUnits(
      missingIds.length ? missingIds : unitIds
    );
  };

  return (
    <Stack
      direction="row"
      spacing={2}
      alignItems="end"
      justifyContent="end"
      sx={{ "&.MuiStack-root": { mt: "auto" } }}
    >
      {!props.isHighlightingUnits && (
        <OtherUnitsTable
          units={props.otherUnitsData}
          handleClickUnit={props.handleClickUnit}
        />
      )}
      <Stack direction="column" spacing={2}>
        <Box
          sx={{
            ml: 2,
            pt: 1,
            height: "auto",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <ButtonSmall
            label={`${
              props.isHighlightingUnits ? "show all" : "isolate"
            } units`}
            onClick={() =>
              props.setIsHighlightingUnits(!props.isHighlightingUnits)
            }
            filled={props.isHighlightingUnits}
            fixedWidth={104}
          />
        </Box>
        <Box
          sx={{
            ml: 2,
            py: 1,
            height: "auto",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <ButtonSmall
            label={`${isComparing ? "- compare all" : "+ compare all"}`}
            onClick={compareOtherUnits}
            filled={isComparing}
            fixedWidth={104}
          />
        </Box>
      </Stack>
    </Stack>
  );
}
