import {useState} from "react";
import "./ComparisonArrow.css"

const ComparisonArrow = ({ subject, comparator, field }) => {
  let className

  if (!comparator
      || !subject[field]
      || !comparator[field]
      || subject.unit_id === comparator.unit_id) {
    className = "ComparisonArrow-null"
  } else if (subject[field] < comparator[field]) {
    className = "ComparisonArrow-down"
  } else if (subject[field] > comparator[field]) {
    className = "ComparisonArrow-up"
  } else {
    className = "ComparisonArrow-same"
  }

  return (
    <svg className={["ComparisonArrow", className].join(" ")} width="10" height="8" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 3L5 8L10 3H0Z"/>
    </svg>
  )
}

export default ComparisonArrow;
