import {useEffect, useMemo, useState} from "react";
import ButtonSmall from "../ButtonSmall/ButtonSmall"
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Popper from '@mui/material/Popper';
import {styled} from "@mui/material/styles";
import { CategoryIcon } from "../Category/Category"
import {Typography} from "@mui/material";
import {categoryLabeltoColourMap} from "../../js/colourUtils";
import {formatUnitId} from "../../js/format";

// this determines what we're searching based on, and how it appears after being selected
const getOptionLabel = d => {
  if (typeof(d) === "string") {
    return d
  } else {
    return `${d.trading_as}, ${formatUnitId(d.parsed_unit_id)} ${d.unit_name}`;
  }
}


const Search = ({ unitsData, handleSearchSelectUnit, comparisonUnits, handleClickCompareUnit, includeCategories, handleSearchSelectCategory }) => {

  const [options, setOptions] = useState([])

  // count how many units belong to each category once so that we can tell if every unit from a category is selected
  const categoryToCountMap = useMemo(() => {
    let countMap = {}
    for (let i = 0; i < unitsData.length; i++) {
      if (countMap[unitsData[i].category]) {
        countMap[unitsData[i].category]++
      } else {
        countMap[unitsData[i].category] = 1
      }
    }
    return countMap
  }, [unitsData])

  useEffect(() => {
    setOptions([
      ...unitsData.sort((a, b) => {
        // put vacant units at the end of the list
        if (b.trading_as.trim() === "" || typeof(b.trading_as) === "undefined" || b.trading_as === null) return -2;
        if (a.trading_as.trim() === "" || typeof(a.trading_as) === "undefined" || a.trading_as === null) return 2;
        return a.trading_as > b.trading_as ? 1 : -1
      }),
      ...(includeCategories ? Object.keys(categoryLabeltoColourMap) : []),
    ])
  }, [unitsData])

  return (
    <StyledAutocomplete
      id="unit-search"
      size={"small"}
      limitTags={2}
      onChange={handleSearchSelectUnit}
      options={options}
      getOptionLabel={getOptionLabel}
      renderOption={(props, option, search) => (
        typeof(option) === "string" ?
          // determine if the category has already been selected (used for isCompared argument) by checking the count of that category that are in comparisonUnits
          SearchCategoryOption(props, option, search, handleSearchSelectCategory, categoryToCountMap[option] === unitsData.filter(d => comparisonUnits.indexOf(d.parsed_unit_id) !== -1 && d.category === option).length)
          : SearchOption(props, option, search, handleClickCompareUnit, handleSearchSelectUnit, comparisonUnits)
      )}
      renderInput={(params) => (
        <TextField {...params} label="Search Units" autoFocus placeholder={`Search by ${includeCategories ? "category, " : ""}unit or tenant name`} />
      )}
      PopperComponent={StyledPopper}
      sx={{ width: '500px' }}
    />
  )
}

const StyledAutocomplete = styled(Autocomplete)({
  '& .MuiOutlinedInput-root': {
    background: "var(--white)",
    '& .MuiOutlinedInput-notchedOutline': {
      border: "2px solid var(--grey2)",
     },
    borderRadius: 20,
  },


})

const StyledPopper = styled(Popper)({
  '& .MuiAutocomplete-listbox': {
    ['-msOverflowStyle']: "none",  /* IE and Edge */
    scrollbarWidth: "none",  /* Firefox */
  },
  '& .MuiAutocomplete-listbox::-webkit-scrollbar': {
    display: "none",  /* Chrome, Safari and Opera */
  },

})

const SearchCategoryOption = (props, option, search, handleSearchSelectCategory, isCompared) => {
  // if the same number of this category are selected as the total then we've already selected them all
  return (
    <li key={option} {...props} style={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
      <div style={{padding: 0, margin: 0, display: "flex", flexDirection: "row"}}>
        <CategoryIcon name={option}/>&nbsp;&nbsp;<Typography>Category: <span
        style={{}}>{highlightSubstring(option, search.inputValue)}</span></Typography>
      </div>
      <ButtonSmall label={isCompared ? "remove all" : "compare all"} filled={isCompared} onClick={(e) => {
        e.stopPropagation();
        handleSearchSelectCategory(e, option, isCompared)
      }}/>
    </li>
  )
}

const SearchOption = (props, option, search, handleClickCompareUnit, handleSearchSelectUnit, comparisonUnits) => {

  let trading_as = option.trading_as;

  trading_as = highlightSubstring(trading_as, search.inputValue);
  let unitId = highlightSubstring(formatUnitId(option.parsed_unit_id), search.inputValue);
  let unitName = highlightSubstring(option.unit_name, search.inputValue);

  const isCompared = comparisonUnits.indexOf(parseInt(option.parsed_unit_id)) !== -1

  return (
    <li key={option.unit_id} {...props} style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
      <div style={{ padding: 0, margin: 0, display: "flex", flexDirection: "row" }}>
        <CategoryIcon name={option.category} />
        <Typography style={{ maxWidth: 350, whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis",}}>
          &nbsp;&nbsp;<span>{trading_as}</span>&nbsp;<span style={{ color: "var(--grey2)", }}>({unitId} | {unitName})</span>
        </Typography>
      </div>
      <ButtonSmall label={isCompared ? "- compare" : "+ compare"} filled={isCompared} onClick={(e) => {
        e.stopPropagation();

        handleClickCompareUnit(option.unit_id)
      }} />
    </li>
  )
}

/**
 * If the value contains the substring, replace the substring with bold text and make all the text black.
 */
const highlightSubstring = (value, substr) => {
  value = value.toString()
  if (substr === "") return value;
  const index = value.toLowerCase().indexOf(substr.toLowerCase())
  if (index !== -1) {
    return (
      <span style={{color: "var(--black)"}}>{value.slice(0, index)}<span style={{fontWeight: "bold"}}>{substr}</span>{value.slice(index + substr.length)}</span>
    )
  } else {
    return value;
  }
}


export default Search;
