import { Stack } from "@mui/material";
import { PropsWithChildren } from "react";
import "./PageContainer.css";

export default function PageContainer(props: PropsWithChildren) {
  return (
    <Stack
      className={"UnitComparison"}
      direction={"row"}
      justifyContent={"center"}
      alignItems={"flex-start"}
    >
      {props.children}
    </Stack>
  );
}
