
import {Box, Stack, ToggleButton, Typography} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import {colours} from "../../theme";
import {categoryLabeltoColourMap} from "../../js/colourUtils";

const Category = ({ name, selected, onChange }) => {
  return (
    <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"} style={{ width: "100%" }}
           onClick={() => onChange(name)} >
      <Stack direction={"row"} spacing={1} alignItems={"center"} >
        <ToggleButton style={{ padding: 2, background: selected ? colours.red : colours.white }}
          value="check"
          selected={selected}
        >
          <CheckIcon fontSize={"small"} style={{ color: "#fff" }} />
        </ToggleButton>
        <Typography>{name}</Typography>
      </Stack>
      <CategoryIcon name={name} />
    </Stack>
  )
}

const CategoryIcon = ({ name }) => {
  if (!categoryLabeltoColourMap[name]) {
    name = "Null"
  }
  return <Box sx={{ margin: 0, width: "5px", height: "20px", background: categoryLabeltoColourMap[name], border: name === "Vacant" ? "0.5px solid var(--grey2)" : "inherit", boxSizing: "border-box" }}/>
}

export default Category;
export { CategoryIcon };
