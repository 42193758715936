
import { createTheme } from '@mui/material/styles';

const colours = {
  background: "#F4E8E2",
  white: "#FFFFFF",
  pink: "#F4E8E2",
  black: "#001B24",
  red: "#D11518",
  purple: "#a985c7",
  grey1: "#333333",
  grey2: "#aaaaaa",
  grey3: "#f5f5f5",
  grey4: "#ececec",
  red3: "#D11518",
  red2: "#E94A2E",
  yellow: "#FFDC44",
  green: "#008864",
  green1: "#047a5a",
  green2: "#086e52",
  green3: "#165b44",
  green4: "#28493e",
  blue: "#808d91",
  blue2: "#2D4295",
  blue2l: "#6d82c7",
  blue3: "#A2AAB4",
  blue4: "#465db9",
  density1: "#c3d4d8",
  density2: "#9ab5bb",
  density3: "#70979f",
  density4: "#497a85",
  density5: "#255e6b",
  mensFashion: "#2d4295",  // blue2
  mensFashion2: "#818EBF",
  leisure: "#008864", // var(--green)
  jewellery: "#668E99",
  teal: "#9ebfc4",
  mobilePhones: "#9ebfc4",
  footwear: "#E94A2E", // var(--red2)
  foodAndBeverage: "#8BCAAE",
  ladiesFashion: "#E4B9D8",
  healthAndBeauty: "#FFDC44", // var(--yellow)
  mixedFashion: "#6D7E89",
  mSU: "#D1D5D9",
  specialist: "#E9EDF8",
  null: "#aaaaaa", // var(--grey2)
  kiosk: "#f56edf", // bright pink
  storage: "#b3a29a",  // like the pink background, but darker
  vacant: "#ffebe0", // like the pink background, but lighter
  atm: "#8BCAAE",  // foodAndBeverage

  plOrange: "#E3A360",
  plGreen: "#91BF81",
  plYellow: "#F4E868",
  plBlue: "#95BCE3",
}

// https://mui.com/material-ui/customization/default-theme/
const theme = createTheme({
  palette: {
    primary: {
      main: colours.grey1,
      light: colours.grey2,
      dark: colours.black,
    },
    secondary: {
      main: colours.red,
    },
    text: {
      primary: colours.black,
    }
  },
  typography: {
    fontFamily: [
      'Lexend', '-apple-system', 'BlinkMacSystemFont', 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'sans-serif'].join(','),
    fontSize: 12,
    h2: {
      fontSize: 24,
      fontWeight: 600,
    },
    h3: {
      fontSize: 16,
      // fontWeight: 600,
    },
    h4: {
      fontSize: 24,
      fontWeight: 600,
      fontFamily: "'Barlow Condensed', sans-serif",
    },
    h5: {
      fontSize: 14,
      fontWeight: 700,
    },
    h6: {
      fontSize: 14,
      fontWeight: 700,
      color: colours.red,
    }
  },

});

export default theme;

export { colours };
