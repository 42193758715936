import {colours} from "../../theme";
import * as colourUtils from "../../js/colourUtils";


/**
 * Calculates the values for the data overlay selected and returns the colour.
 * @param d
 * @param unitData
 * @param dataOverlay
 * @param turnoverCalculatedValues
 * @param turnoverCalculatedValuesCategories
 * @returns {string|*|string}
 */
export const dataOverlayColour = (
  d,
  unitData,
  dataOverlay,
  turnoverCalculatedValues,
  turnoverCalculatedValuesCategories,
) => {

  switch (dataOverlay) {
    case "none":
      return colours.blue3

    case "category":
      if (unitData.trading_as === "" && unitData.rent === "") {
        unitData.category = "Vacant";
      }
      return colourUtils.categoryLabeltoColourMap[unitData.category] ?? colours.null

    case "leasing_status":
      return colourUtils.leasingStatusLabelToColourMap[unitData.leasingStatus ?? "None"]

    case "turnover":
      return colourUtils.turnoverLabeltoColourMap[colourUtils.turnoverLabel(unitData.last_year_turnover)]

    case "sales_density":
      if (!unitData.last_year_turnover) return colours.pink;
      if (!unitData.total_area || unitData.total_area === 0) return colours.pink;

      const sales_density = unitData.last_year_turnover / unitData.total_area
      return colourUtils.salesDensityDynamicColourScale(sales_density, unitData.total_area)

    case "rsr":
      return colourUtils.rsrLabeltoColourMap[colourUtils.rsrLabel(unitData)]

    case "ocr":
      return colourUtils.ocrLabelDynamic(unitData.occupancy_cost_ratio, unitData.category).colour

    case "lease_expiry":
      return colourUtils.leaseExpiryLabelToColourMap[colourUtils.leaseExpiryLabel(unitData)]

    case "break_option":
      return colourUtils.breakOptionLabelToColourMap[colourUtils.breakOptionLabel(unitData)]

    case "mat_vs_category":
      if (turnoverCalculatedValues && turnoverCalculatedValuesCategories) {
        const unitValues = turnoverCalculatedValues.find(dd => dd.unit_id === d.unit_id)
        const categoryValues = turnoverCalculatedValuesCategories.find(dd => dd.category === unitData.category)
        if (!unitValues.MATGrowth) {
          return colours.pink;
        } else if (!categoryValues) {
          return colours.pink
        } else if (!categoryValues.MATGrowth) {
          return colours.pink
        } else {
          return colourUtils.turnoverGrowthRateColourScale(unitValues.MATGrowth - categoryValues.MATGrowth)
        }
      } else {
        return colours.pink
      }
    case "mat_vs_category_monthly":
      if (turnoverCalculatedValues && turnoverCalculatedValuesCategories) {
        const unitValues = turnoverCalculatedValues.find(dd => dd.unit_id === d.unit_id)
        const categoryValues = turnoverCalculatedValuesCategories.find(dd => dd.category === unitData.category)
        if (!unitValues.MATGrowth) {
          return colours.pink;
        } else if (!categoryValues) {
          return colours.pink
        } else if (!categoryValues.MATGrowthMonthly) {
          return colours.pink
        } else {
          return colourUtils.turnoverGrowthRateColourScale(unitValues.MATGrowthMonthly - categoryValues.MATGrowthMonthly)
        }

      } else {
        return colours.pink
      }

    case "mat_growth":
      if (turnoverCalculatedValues) {
        const values = turnoverCalculatedValues.find(dd => dd.unit_id === d.unit_id)

        if (!values) {
          return colours.pink;
        }

        if (!values.MATGrowth) {
          return colours.pink;
        } else {
          return colourUtils.turnoverGrowthRateColourScale(values.MATGrowth)
        }

      } else {
        return colours.pink
      }
    case "mat_growth_monthly":
      if (turnoverCalculatedValues) {
        const values = turnoverCalculatedValues.find(dd => dd.unit_id === d.unit_id)
        if (!values) {
          return colours.pink;
        } else if (!values.MATGrowthMonthly) {
          return colours.pink
        } else {
          return colourUtils.turnoverGrowthRateColourScale(values.MATGrowthMonthly)
        }
      } else {
        return colours.pink

      }

    // 5 - monthly MAT density
    case "mat_density_monthly":
      if (turnoverCalculatedValues) {
        const values = turnoverCalculatedValues.find(dd => dd.unit_id === d.unit_id)
        if (!values) {
          return colours.pink;
        } else if (!values.selectedMonthTurnover) {
          return colours.pink
        } else if (!unitData.total_area || unitData.total_area === 0) {
          return colours.pink
        } else {
          return colourUtils.salesDensityDynamicColourScale(12 * values.selectedMonthTurnover / unitData.total_area, unitData.total_area)
          // return colourUtils.salesDensityColourScale(12 * values.selectedMonthTurnover / unitData.total_area)
        }
      } else {
        return colours.pink
      }

    // 6 - MAT density
    case "mat_density":
      if (turnoverCalculatedValues) {
        const values = turnoverCalculatedValues.find(dd => dd.unit_id === d.unit_id)
        if (!values) {
          return colours.pink;
        } else if (!values.last12MonthsMAT) {
          return colours.pink
        } else if (!unitData.total_area || unitData.total_area === 0) {
          return colours.pink
        } else {
          return colourUtils.salesDensityDynamicColourScale(values.last12MonthsMAT / unitData.total_area, unitData.total_area)
          // return colourUtils.salesDensityColourScale(values.last12MonthsMAT / unitData.total_area)
        }
      } else {
        return colours.pink
      }

    // 7 - MAT Based OCR = Occupational costs of the year / MAT
    // Occupational costs = Service rate [static within a year] + Insurance [static within a year] + Business rate [Static within a year] + rent[month])
    case "mat_ocr":
      if (turnoverCalculatedValues) {
        const values = turnoverCalculatedValues.find(dd => dd.unit_id === d.unit_id)
        if (!values) {
          return colours.pink;
        } else if (!values.last12MonthsMAT) {
          return colours.pink
        } else if (!unitData.total_occupational_costs || !unitData.rent) {
          return colours.pink
        } else {
          const ocr = (unitData.total_occupational_costs + unitData.rent) / values.last12MonthsMAT
          return colourUtils.ocrLabelDynamic(ocr, unitData.category).colour
        }
      } else {
        return colours.pink
      }


    default:
      return "white"
  }
}