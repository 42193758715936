
export function download(filename, text) {
  const element = document.createElement('a');
  element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
  element.setAttribute('download', filename);

  element.style.display = 'none';
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
}

/**
 * Converts the array of objects provided to a CSV (as a string) with the headings provided. Any values that aren't
 * included in the headings won't be in the CSV string.
 */
export function arrayToCSV(data, headings) {
  if (data.length === 0) return "";

  const rows = [headings];

  data.forEach(d => {
    const row = headings.map(heading => {
      const value = d[heading]
      // nulls should become empty cells
      if (value === null || typeof(value) === "undefined" || Number.isNaN(value)) {
        return ""
      } else {
        return value
      }
    })
    rows.push(row)
  });

  return rows.map(row => row.map(JSON.stringify)).join('\r\n')
}