import React, {useState} from 'react';
import {DatePicker, LocalizationProvider} from '@mui/x-date-pickers';
import { Box, Typography, Stack } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { enGB } from "date-fns/locale/en-GB";
import {styled} from "@mui/material/styles";


// One component which allows us to create a date range of the break options and lease expiry
const DateRangeSelect = ({  dateRange, setDateRange, title}) => {

  const [isDateRangeValid, setDateRangeValid] = useState(true);


  const handleStartDateChange = date => {
    const isValid = isValidDateRange(date, dateRange.endDate);
    setDateRangeValid(isValid);
    setDateRange(prevState => ({ ...prevState, startDate: date }));
  };

  const handleEndDateChange = date => {
    const isValid = isValidDateRange(dateRange.startDate, date);
    setDateRangeValid(isValid);
    setDateRange(prevState => ({ ...prevState, endDate: date }));
  };

  const isValidDateRange = (startDate, endDate) => {
    if (startDate && endDate) {
      return startDate <= endDate;
    }
    return true;  // If one of the dates is not selected, we assume it's valid.
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enGB}>
      <div>
        <Typography  variant="h5" style={{paddingBottom:'12px'}}>{title}</Typography>
        <Stack direction={"column"} spacing={1}>
          <StyledDatePicker
            label={'From'}
            value={dateRange.startDate}
            onChange={handleStartDateChange}
            error={!isDateRangeValid}
          />
          <StyledDatePicker
            label={'To'}
            value={dateRange.endDate}
            onChange={handleEndDateChange}
            error={!isDateRangeValid}
          />
        </Stack>
      </div>
    </LocalizationProvider>
  );
};

const StyledDatePicker = styled(DatePicker)(({ error }) => ({
  '& .MuiInputBase-root': {
    width: '100%',
    height: 30,
    borderRadius: 10,
    background: 'var(--white)',
    borderColor: error ? 'var(--red)' : 'none',
  },

  '& .MuiInputLabel-outlined': {
    lineHeight: 0,
    overflow: 'visible'
  },

  '& .MuiInputLabel-shrink': {
    lineHeight: 'normal',
    overflow: 'hidden',
  },

  '& .MuiOutlinedInput-input': {
    color: error ? 'var(--red)' : 'none',
  },

  '& .MuiSvgIcon-root path': {
    fill: "var(--mSU)"
  },

  '& .MuiButtonBase-root:hover .MuiSvgIcon-root path': {
    fill: "var(--grey2)"
  },

}));




export default DateRangeSelect;
