import labelOffsets from "./labelOffsets";

export const getCenter = (vals, unit_id="") => {
  const arrX = vals.map(arr => { return arr[0]; })//.sort((a, b) => a - b).slice(1, -1);
  const arrY = vals.map(arr => { return arr[1]; })//.sort((a, b) => a - b).slice(1, -1);
  let x = Math.min(...arrX) + (Math.max(...arrX) - Math.min(...arrX))/2
  let y = Math.min(...arrY) + (Math.max(...arrY) - Math.min(...arrY))/2

  if (labelOffsets[unit_id]) {
    x += labelOffsets[unit_id][0]
    y += labelOffsets[unit_id][1]
  }

  return [x, y]
}

export const offsetByFloor = (floor_id) => {
  if (floor_id === 5) {
    return [0, -987]
  } else if (floor_id === 1) {
    return [260, -500]
  } else {
    return [0, 0]
  }
}