import {useEffect, useState} from "react";
import {Box, Stack, Typography} from "@mui/material";
import Slider from "../Slider/Slider";
import Input from "@mui/material/Input";
import {styled} from "@mui/material/styles";
import {colours} from "../../theme";

// max unit size is hard coded
export const MAX_VALUE = 50000;

const UnitSizeSelect = ({ onChange }) => {

  const [value, setValue] = useState([0, MAX_VALUE]);

  const handleChange = event => {
    const newValues = event.target.value;
    setValue([Math.max(0, newValues[0]), Math.min(MAX_VALUE, newValues[1])])
  }

  useEffect(() => {
    onChange([...value])
  }, [value])

  return (
    <Box>
      <Typography variant="h5">Unit size (sqft)</Typography>
      <Slider valueLabelDisplay="off" value={value} defaultValue={[0, MAX_VALUE]} max={MAX_VALUE} onChange={handleChange} />
      <Stack direction={"row"} justifyContent={"space-between"}>
        <StyledInput
          name={"unit-size-min"}
          disableUnderline
          value={value[0]}
          onChange={event => {
            if (isNaN(event.target.value)) return;
            setValue(prevState => [Math.max(0, event.target.value), prevState[1]])
          }}
          inputProps={{
            step: 100,
            min: 0,
            max: MAX_VALUE,
          }}
          aria-label={'unit-size-filter-minimum-value'}
        />
        <StyledInput
          name={"unit-size-max"}
          disableUnderline
          value={value[1] + (value[1] === MAX_VALUE ? "+" : 0)}
          onChange={event => {
            let newValue = parseInt(event.target.value)
            // bug fix: if value remains at the maximum after being changed then it the '+' will be added to it again
            if (newValue === MAX_VALUE) {
              newValue = MAX_VALUE/10
            }
            if (isNaN(newValue)) {
              newValue = 0
            }
            setValue(prevState => [prevState[0], Math.min(MAX_VALUE, newValue)])
          }}
          inputProps={{
            step: 100,
            min: 0,
            max: MAX_VALUE,
          }}
          aria-label={'unit-size-filter-maximum-value'}
        />
      </Stack>
    </Box>
  )
}

const StyledInput = styled(Input)({
  '& .MuiInputBase-input': {
    width: 50,
    background: colours.grey3,
    padding: 2,
  },
})

export default UnitSizeSelect;
