import {colours} from "../../theme";
import {Stack, Typography} from "@mui/material";
import "./Tab.css"

export default function Tab({ selected, label, counter, onClick }) {
  return (
    <Stack className={"Tab" + (selected ? " Tab-selected" : "")} alignItems={"center"} justifyContent={"center"} onClick={onClick}>
      <Counter count={counter} />
      <Typography variant={"h4"}>{label}</Typography>
    </Stack>
  )
}

const Counter = ({ count }) => {
  if (typeof(count) === "undefined" || count === 0) return null;

  return <div style={{
    background: colours.red,
    height: 30, width: 30, borderRadius: 20,
    display: "flex", alignItems: "center", justifyContent: "center",
    position: "absolute", top: -10, right: -10 }}>
    <Typography style={{ color: colours.white }}>
      {count}
    </Typography>
  </div>
}