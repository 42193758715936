import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { NA } from "../../js/format";
import ButtonGroup from "./Buttons/ButtonGroup";
import TabGroup from "./Tabs/TabGroup";

/**
 * Box displaying information about a single unit.
 * @param unitData: One row from the leasing schedule report
 * @param unitTurnoverData: All MAT Turnover calculated values for the selected unit
 * @param notes: Array of all notes
 * @param handleRefreshNotesForUnit: Callback that runs after updating notes to refresh note data
 * @param isInternal: If false then sensitive fields are hidden
 * @param isVerifiedUser: If true then business plan is displayed and user can edit notes
 * @param selectedUnit: This is only required for the help message when there is no unit data
 * @param handleError: Callback to display an error message
 * @param handleEditUnit: API post request to change unit details
 * @param otherUnitsData: Data for all the other units that fall under the same "trading_as" as the selected unit
 * @param handleClickUnit: We need to "click" a storage unit's parent retail unit
 * @param isHighlightingUnits: If true, any unit not in the selected unit's "trading_as" family will not be displayed
 * @param setIsHighlightingUnits: Sets the above boolean (in App.js)
 */
const UnitDetails = ({
  unitData,
  unitTurnoverData,
  notes,
  handleRefreshNotesForUnit,
  isInternal,
  isVerifiedUser,
  selectedUnit,
  handleError,
  handleEditUnit,
  otherUnitsData,
  handleClickUnit,
  handleClickCompareOtherUnits,
  comparisonUnits,
  isHighlightingUnits,
  setIsHighlightingUnits,
}) => {
  const [tab, setTab] = useState("details"); // "details" | "docs" | "notes" | "other-units"

  const isStorage = unitData?.category?.toLowerCase() === "storage";
  const isKiosk = unitData?.category?.toLowerCase() === "kiosk";

  useEffect(() => {
    // We need to force the tab to switch back to "details" if the user selects
    // a new unit.
    setTab("details");
  }, [selectedUnit]);

  return (
    <Box
      sx={{
        width:
          isHighlightingUnits && tab === "other-units"
            ? "auto"
            : "calc(100% - 80px)",
        maxWidth: 1500,
        position: "absolute",
        bottom: 40,
        left: 40,
      }}
    >
      <Stack
        direction={"column"}
        spacing={1}
        sx={{
          background: "white",
          borderRadius: 4,
          margin: 0,
          padding: 3,
          minHeight: 180,
          border: "2px solid var(--grey1)",
        }}
      >
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <Stack direction={"row"} alignItems={"baseline"} spacing={1}>
            {unitData === null || typeof unitData === "undefined" ? (
              <>
                <Typography variant={"h2"}>No Data</Typography>
                <Typography variant={"h3"}>Unit ID: {selectedUnit}</Typography>
              </>
            ) : (
              <>
                <Typography variant={"h2"}>
                  {unitData.trading_as !== ""
                    ? unitData.trading_as
                    : unitData.tenant !== ""
                    ? unitData.tenant
                    : NA}
                </Typography>
                <Typography variant={"h3"}>{unitData.demise ?? NA}</Typography>
              </>
            )}
          </Stack>
          {isInternal && (
            <ButtonGroup
              isStorage={isStorage}
              otherUnitsData={otherUnitsData}
              unitData={unitData}
              selectedUnit={selectedUnit}
              handleClickUnit={handleClickUnit}
              tab={tab}
              setTab={setTab}
              notes={notes}
            />
          )}
        </Stack>
        <TabGroup
          currentTab={tab}
          unitData={unitData}
          otherUnitsData={otherUnitsData}
          handleClickUnit={handleClickUnit}
          isHighlightingUnits={isHighlightingUnits}
          setIsHighlightingUnits={setIsHighlightingUnits}
          comparisonUnits={comparisonUnits}
          handleClickCompareOtherUnits={handleClickCompareOtherUnits}
          isVerifiedUser={isVerifiedUser}
          handleRefreshNotesForUnit={handleRefreshNotesForUnit}
          handleError={handleError}
          notes={notes}
          selectedUnit={selectedUnit}
          handleEditUnit={handleEditUnit}
          isKiosk={isKiosk}
          isStorage={isStorage}
          isInternal={isInternal}
          unitTurnoverData={unitTurnoverData}
        />
      </Stack>
    </Box>
  );
};

export default UnitDetails;
