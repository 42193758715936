import {useRef} from "react";
import {Stack} from "@mui/material";
import {rasterize} from "../../js/svgUtils";
import "./ScreenshotButton.css"
import {logEvent} from "../../js/interface";

const ScreenshotButton = ({ fileName, elementId }) => {

  const ref = useRef();

  return (
    <Stack className={"ScreenshotButton"}
           justifyContent={"center"}
           alignItems={"center"}
           onClick={(e) => {
      // prevent infinite loops
      if (e.target === ref.current) return;

      // TODO: include fonts
      rasterize(document.querySelector(elementId))
        .then(result => {
          console.log(result)
          const url  = window.URL.createObjectURL(result);
          console.log(url)

          const a = ref.current;

          a.href = url;
          // a.download = fileName;
          a.click();
          window.URL.revokeObjectURL(url);
        })

      logEvent({ eventName: "exportScreenshot" })
    }}>
      <a ref={ref} download={fileName} href={null}></a>
      <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M5.17 2L7 0H13L14.83 2H18C19.1 2 20 2.9 20 4V16C20 17.1 19.1 18 18 18H2C0.9 18 0 17.1 0 16V4C0 2.9 0.9 2 2 2H5.17ZM5 10C5 12.76 7.24 15 10 15C12.76 15 15 12.76 15 10C15 7.24 12.76 5 10 5C7.24 5 5 7.24 5 10ZM13.1998 9.9998C13.1998 11.7671 11.7671 13.1998 9.9998 13.1998C8.23249 13.1998 6.7998 11.7671 6.7998 9.9998C6.7998 8.23249 8.23249 6.7998 9.9998 6.7998C11.7671 6.7998 13.1998 8.23249 13.1998 9.9998Z" fill="#D11518"/>
      </svg>
    </Stack>
  )
}

export default ScreenshotButton;
