

// text to display when value is undefined
export const NA = "-";

/**
 * This conversion has been derived by looking at Pradera's leasing plans
 */
export const demiseToCode = (demise) => {

  if (!demise) return "";

  // exceptions
  if (demise.includes("Wilderspool")) return demise;
  if (demise.includes("Wpool")) return demise;
  if (demise.includes("Petrol")) return demise;
  if (demise.includes("Leisure")) return demise;
  if (demise.includes("Landlord")) return demise;
  if (demise.includes("Paddington")) return demise;
  if (demise.includes("Cafe 5 ref AA1")) return demise;
  if (demise.includes("Mulitplex")) return demise;
  if (demise.includes("Bus Station")) return demise;
  if (demise.includes("Carpark")) return demise;
  if (demise === "Big Screen Commercial") return demise;
  if (demise === "Office Space") return demise;
  if (demise === "Taxi Office") return demise;
  if (demise === "Event Hire") return demise;

  let code = demise
    .replace("L Mall Restaurant U", "R")
    .replace("L Orient Rest U", "R")
    .replace("Restaurant Unit R", "R")
    .replace("Restaurant Unit", "R")
    .replace("Restaurant U", "R")
    .replace("Upper Mall Restnt U", "R")
    .replace("UpperMall Restnt U", "R")
    .replace("Lower Mall Unit", "L")
    .replace("L Mall U", "L")
    .replace("Lower Mall U", "L")
    .replace("Lower Mall", "L")
    .replace("Kiosk", "K")
    .replace("Remote Storage Area", "RS")
    .replace("Remote Storage", "RS")
    .replace("Remote Store", "RS")
    .replace("Remote Store Room", "RS")
    .replace("RemoteStore", "RS")
    .replace("R Store", "RS")
    .replace("REMOTES-RE", "RS")
    .replace("ItalianGallerie Ksk", "G")
    .replace("Major Shop Unit", "MSU")
    .replace("Major Shop U", "MSU")
    .replace("Upper Mall Unit", "U")
    .replace("Upper Mall U", "U")
    .replace("UpperMall U", "U")
    .replace(" , U", "-")
    .replace(", U", "-")
    .replace(",U", "-")
    .replace(",", "-")
    .replace("to", "-")

  return code.replaceAll(" ", "").toUpperCase()
}

export const formatDate = (d) => {
  if (d === null || typeof(d) === "undefined" || d === "") return NA;
  try {
    d = new Date(d)
  }
  catch (e) {
    console.error("Invalid date:", d)
    return NA;
  }
  return d.toLocaleDateString()
}

export const formatInt = (num) => {
  if (num === null || typeof(num) === "undefined" || num === "") return NA;
  if(typeof(num) === "string") {
    try {
      num = parseInt(num, 10)
    }
    catch (e) {
      console.error("Invalid integer:", e)
      return num;
    }
  } else if (isNaN(num)) {
    return NA;
  }
  return (num).toLocaleString("en-GB", {minimumFractionDigits: 0, maximumFractionDigits: 0})
}

export const formatCurrency = (num) => {
  if (num === null || typeof(num) === "undefined" || num === "") return NA;
  if (typeof(num) === "string") {
    try {
      num = parseInt(num, 10)
    }
    catch (e) {
      console.error("Invalid currency:", e)
      return num;
    }
  } else if (isNaN(num)) {
    return NA;
  }
  return (num).toLocaleString("en-GB", {style: "currency", currency: "GBP", minimumFractionDigits: 0, maximumFractionDigits: 0})
}

export const formatPercent = (num) => {
  if (num === null || typeof(num) === "undefined" || num === "" || isNaN(num)) return NA;
  return (num * 100).toFixed(0) + "%";
}

export const formatUnitId = (intUnitId) => {
  return `00000${intUnitId}`.slice(-5)
}

export const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

export const formatMonth = (monthNumber) => {
  return ["-", ...months][monthNumber];
}