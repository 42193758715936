import React, { useEffect, useState } from 'react';
import * as firebaseui from "firebaseui";
import firebase from 'firebase/compat/app';
import { onAuthStateChanged } from "firebase/auth";
import {Stack} from "@mui/material";
import "./SignInScreen.css"

let project;
// if running in prod, we'll be at a traffordcentre.co.uk subdomain
if (window.location.host.endsWith("traffordcentre.co.uk")) {
  project = "tc-unit-comparator-prod-99b6"
} else {
  // in dev and test the GCP project is in the URL
  project = window.location.host.split(".")[0]
}

let apiKey;
if (project.includes("prod")) {
  apiKey = "AIzaSyCIt3j4ICdlvTjY4Z1OCHbFLnqkMek1Bq8";
} else if (project.includes("test")) {
  apiKey = "AIzaSyCQnGnzXDgenld9Jlo7FN5jGTPlxyCdVA0";
} else {
  apiKey = "AIzaSyASsoNIu7lg7QKjWyiEO3fuQ92XELBmd_0";
}

// Configure Firebase.
const config = {
  apiKey,
  authDomain: project + ".firebaseapp.com"
};
firebase.initializeApp(config);

// Configure FirebaseUI.
const uiConfig = {
  // Popup signin flow rather than redirect flow.
  signInFlow: 'popup',
  signInOptions: [
    firebase.auth.EmailAuthProvider.PROVIDER_ID
  ],
  callbacks: {
    // Avoid redirects after sign-in.
    signInSuccessWithAuthResult: () => false,
  },
};

const ELEMENT_ID = "firebaseui_container";

/**
 * See docs/authentication for more information.
 * Note: The sign in component must stay mounted the entire time.
 *       The DOM element will be hidden when the user is signed in.
 * Note: The 'sign out' button is a separate component imported directly into App.
 */
const SignInScreen = ({ handleSignInOut }) => {
  // const firebaseAuth = new firebaseui.auth.AuthUI(firebase.auth())
  const firebaseAuth = firebase.auth()

  const [isSignedIn, setIsSignedIn] = useState(false); // Local signed-in state.

  // Listen to the Firebase Auth state and set the local state.
  useEffect(() => {
    const unregisterAuthObserver = firebase.auth().onAuthStateChanged(user => {
      setIsSignedIn(!!user);
    });
    return () => unregisterAuthObserver(); // Make sure we un-register Firebase observers when the component unmounts.
  }, []);

  // workaround from: https://github.com/firebase/firebaseui-web-react/issues/172
  React.useEffect(() => {
    // Get or Create a firebaseUI instance.
    const firebaseUiWidget =
      firebaseui.auth.AuthUI.getInstance() ||
      new firebaseui.auth.AuthUI(firebaseAuth);

    if (uiConfig.signInFlow === "popup") firebaseUiWidget.reset();

    // We track the auth state to reset firebaseUi if the user signs out.
    const unregisterAuthObserver = onAuthStateChanged(firebaseAuth, (user) => {
      if (!user && isSignedIn) firebaseUiWidget.reset();
      setIsSignedIn(!!user)
    });

    // Render the firebaseUi Widget.
    firebaseUiWidget.start("#" + ELEMENT_ID, uiConfig);

    return () => {
      unregisterAuthObserver();
      firebaseUiWidget.reset();
    };
  }, [uiConfig, firebaseAuth, isSignedIn]);

  useEffect(() => {
    handleSignInOut(isSignedIn);
  }, [isSignedIn])

  return (
    <Stack direction={"row"} alignItems={"center"} justifyContent={"center"} style={isSignedIn ? null : { height: "100%", width: "100%" }}>
      <div hidden={isSignedIn} style={{ background: "white", borderRadius: 20, padding: 20, minWidth: 300, width: "25vw" }}>
        <div className={"SignInScreen"} id={ELEMENT_ID} />
      </div>
    </Stack>
  );
}

export default SignInScreen;
