import Stack from "@mui/material/Stack";
import OtherUnitsButton from "./OtherUnitsButton";
import NotesButton from "./NotesButton";
import DocsButton from "./DocsButton";
import DetailsButton from "./DetailsButton";
import { Tab } from "../Tabs/TabGroup";

export interface ButtonGroupProps {
  isStorage: boolean;
  otherUnitsData: any[];
  unitData: any;
  selectedUnit: number;
  handleClickUnit: (unitId: number) => void;
  notes: any[];
  tab: Tab;
  setTab: (tab: Tab) => void;
}

export default function ButtonGroup(props: ButtonGroupProps) {
  return (
    <Stack direction={"row"} spacing={1} sx={{ ml: 3 }}>
      <DetailsButton
        onClick={() => props.setTab("details")}
        active={props.tab === "details"}
      />
      {props.otherUnitsData.length ? (
        <OtherUnitsButton
          active={props.tab === "other-units"}
          onClick={() => props.setTab("other-units")}
          storageCount={props.otherUnitsData.length}
          tradingAs={props.unitData.trading_as}
        />
      ) : null}
      <NotesButton
        onClick={() => props.setTab("notes")}
        active={props.tab === "notes"}
        notesCount={
          props.notes.filter(
            (d) => d.unitId === props.selectedUnit && !d.archived
          ).length
        }
      />
      <DocsButton
        onClick={() => props.setTab("docs")}
        active={props.tab === "docs"}
      />
    </Stack>
  );
}
