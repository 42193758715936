import Stack from "@mui/material/Stack";
import { TabProps } from "./TabGroup";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import DropDown from "../../DropDown/DropDown";
import MenuItem from "@mui/material/MenuItem";
import { colours } from "../../../theme";
import {
  formatInt,
  formatDate,
  formatCurrency,
  formatPercent,
  formatUnitId,
  NA,
  formatMonth,
} from "../../../js/format";
import {
  categoryLabeltoColourMap,
  leasingStatusLabelToColourMap,
} from "../../../js/colourUtils";

interface DetailsTabProps extends TabProps {
  //
}

const isNonZeroArea = (value: any) => {
  return (
    value !== "" &&
    value !== null &&
    value !== "0" &&
    value !== 0 &&
    !isNaN(value)
  );
};

export default function DetailsTab(props: DetailsTabProps) {
  // unit area displays differently depending on how many different levels there are
  let unitAreaTotal = "";
  let unitAreaUpper = "";
  let unitAreaLower = "";

  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth() + 1;

  let turnover = null,
    salesDensity = null;

  if (props.unitData !== null && typeof props.unitData !== "undefined") {
    if (props.unitTurnoverData) {
      turnover = props.unitTurnoverData.last12MonthsMAT;
    }

    // calculate salesDensity using MAT Turnover
    if (!!turnover && !!props.unitData.total_area) {
      salesDensity = turnover / props.unitData.total_area;
    }

    unitAreaTotal = formatInt(props.unitData.total_area);

    // lower_ground,lower_mezz,upper_ground,upper_mezz
    // if there is area on the upper and lower floor
    if (
      isNonZeroArea(props.unitData.upper_ground) &&
      props.unitData.upper_ground !== props.unitData.total_area
    ) {
      unitAreaUpper = `${formatInt(props.unitData.upper_ground)}`;
      if (isNonZeroArea(props.unitData.upper_mezz)) {
        unitAreaUpper += ` + ${formatInt(props.unitData.upper_mezz)} mezzanine`;
      }
    }
    if (
      isNonZeroArea(props.unitData.lower_ground) &&
      props.unitData.lower_ground !== props.unitData.total_area
    ) {
      unitAreaLower = `${formatInt(props.unitData.lower_ground)}`;
      if (isNonZeroArea(props.unitData.lower_mezz)) {
        unitAreaLower += ` + ${formatInt(props.unitData.lower_mezz)} mezzanine`;
      }
    } else {
    }

    if (unitAreaUpper !== "" || unitAreaLower !== "") {
      unitAreaTotal += " total";
    }
  }

  return (
    <Stack spacing={2} direction={"row"} justifyContent={"space-evenly"}>
      {props.unitData === null || typeof props.unitData === "undefined" ? (
        <>
          <Typography>
            There is no data for the unit with ID '{props.selectedUnit}'. This
            could be because this unit ID is outdated or incorrect. If so,
            please{" "}
            <a
              href={
                "https://client.support.datasparq.ai/servicedesk/customer/portals"
              }
              target="_blank"
              rel="noreferrer"
              style={{ color: colours.black }}
            >
              raise a support request
            </a>
            . If data exists for this unit/tenant, you should still be able to
            find it by looking up the tenant name in the search bar.
            <br />
            <br />
            Please note: Any notes created for this unit will be lost if the
            unit ID changes.
          </Typography>
          <Typography style={{ width: "50%" }}>&nbsp;</Typography>
        </>
      ) : (
        <>
          <Stack direction={"column"} flex={2}>
            <Typography variant={"h6"}>Overview</Typography>
            <UnitDetailsRow
              label={"Unit ID"}
              value={formatUnitId(props.unitData.parsed_unit_id)}
            />
            {/*<UnitDetailsRow label={`Unit ${props.unitData.unit_id ?? NA}`} value={props.unitData.demise ?? NA} />*/}
            {/*<UnitDetailsRow label={"Tenant Name"} value={props.unitData.trading_as !== "" ? props.unitData.trading_as : (props.unitData.tenant !== "" ? props.unitData.tenant : NA)} />*/}
            {props.isVerifiedUser ? (
              <UnitDetailsDropDown
                label={"Category"}
                value={props.unitData.category}
                options={Object.keys(categoryLabeltoColourMap)}
                onChange={(event: any) => {
                  console.log("User changed category", event.target.value);
                  props.handleEditUnit(
                    props.unitData.id,
                    props.unitData.unit_id,
                    {
                      category: event.target.value,
                    }
                  );
                }}
              />
            ) : (
              <UnitDetailsRow
                label={"Category"}
                value={props.unitData.category ?? NA}
              />
            )}
            {!props.isKiosk && (
              <UnitDetailsRow
                label={"Size"}
                subLabel={"(sqft)"}
                value={unitAreaTotal}
              />
            )}
            {unitAreaUpper !== "" && (
              <UnitDetailsRow
                label={"Size · upper floor"}
                value={unitAreaUpper}
              />
            )}
            {unitAreaLower !== "" && (
              <UnitDetailsRow
                label={"Size · lower floor"}
                value={unitAreaLower}
              />
            )}
            {!props.isStorage && props.isInternal && (
              <>
                {turnover ? (
                  <UnitDetailsRow
                    label={`MAT Turnover`}
                    subLabel={`(${formatMonth(
                      props.unitTurnoverData.selectedMonth
                    ).slice(0, 3)} ${
                      props.unitTurnoverData.selectedYear - 1
                    }-${props.unitTurnoverData.selectedYear
                      .toFixed(0)
                      .slice(2, 4)})`}
                    value={formatCurrency(turnover)}
                  />
                ) : (
                  <UnitDetailsRow label={`MAT Turnover`} value={NA} />
                )}
                <UnitDetailsRow
                  label={"OCR | RSR"}
                  value={`${formatPercent(
                    props.unitData.rent_to_sales_ratio
                  )} | ${formatPercent(props.unitData.occupancy_cost_ratio)}`}
                />
                <UnitDetailsRow
                  label={"Sales Density"}
                  value={
                    salesDensity === null
                      ? NA
                      : `£${formatInt(salesDensity)} per sqft`
                  }
                />
              </>
            )}
          </Stack>
          {props.isInternal && <VerticalDivider />}
          {props.isInternal && (
            <Stack direction={"column"} flex={2.7}>
              <Typography variant={"h6"}>Lease Details</Typography>
              <UnitDetailsRow
                label={"Start date | Expiry date"}
                value={
                  formatDate(props.unitData.start_date) +
                  " | " +
                  formatDate(props.unitData.end_date)
                }
              />
              <UnitDetailsRow
                label={"Rent"}
                value={formatCurrency(props.unitData.rent)}
              />
              {!props.isKiosk && (
                <UnitDetailsRow
                  label={"Rent"}
                  subLabel={"(per sqft)"}
                  value={
                    !props.unitData.total_area || !props.unitData.rent
                      ? NA
                      : "£" +
                        (
                          props.unitData.rent / props.unitData.total_area
                        ).toFixed(0)
                  }
                />
              )}
              {!props.isStorage && (
                <UnitDetailsRow
                  label={"Lease Type"}
                  value={
                    props.unitData.lease_type !== ""
                      ? props.unitData.lease_type
                      : NA
                  }
                />
              )}
              {!props.isStorage && (
                <UnitDetailsRow
                  label={"Turnover %"}
                  value={formatPercent(props.unitData.turnover_rent_percentage)}
                />
              )}
              {/*<UnitDetailsRow label={"Turnover only lease"} value={props.unitData.lease_type === "turnover only" || props.unitData.rent.toString() === "0" ? "yes" : "no"} />*/}
              <UnitDetailsRow
                label={"Break option | Notice date"}
                value={
                  formatDate(props.unitData.break_option) +
                  " | " +
                  formatDate(props.unitData.break_notice_date)
                }
              />
              {!props.isStorage && (
                <UnitDetailsRow
                  label={"1954 act protected lease"}
                  value={
                    props.unitData.outside1954_act === "true"
                      ? "no"
                      : props.unitData.outside1954_act === "false"
                      ? "yes"
                      : NA
                  }
                />
              )}
              {!props.isStorage && props.isVerifiedUser ? (
                <UnitDetailsDropDown
                  label={"Leasing Status"}
                  value={props.unitData.leasingStatus ?? "None"}
                  options={Object.keys(leasingStatusLabelToColourMap)}
                  onChange={(event: any) => {
                    console.log(
                      "User changed leasing status",
                      event.target.value
                    );
                    // we also provide a value for category to prevent setting a null value in the case where the unit is being created for the first time
                    props.handleEditUnit(
                      props.unitData.id,
                      props.unitData.unit_id,
                      {
                        leasingStatus: event.target.value,
                        category: props.unitData.category,
                      }
                    );
                  }}
                />
              ) : (
                !props.isStorage && (
                  <UnitDetailsRow
                    label={"Leasing Status"}
                    value={props.unitData.leasingStatus ?? NA}
                  />
                )
              )}
            </Stack>
          )}
          <VerticalDivider />
          <Stack direction={"column"} flex={2.4}>
            {/**
             Occupational costs have a dynamic label to show the year they apply to.
             Logic provided by Adam Coughlan @ Pradera: The 3 different occupational costs are not updated at the same time.
              Service charge / Insurance label will need to change in December (In december it should change to next year).
              Business Rates are updated every April.
            */}
            <Typography variant={"h6"}>Occupational Costs</Typography>
            <UnitDetailsRow
              label={"Business Rates"}
              subLabel={`(${
                currentMonth >= 4 ? currentYear : currentYear - 1
              })`}
              value={formatCurrency(props.unitData.rates_payable)}
            />
            <UnitDetailsRow
              label={"Service Charge"}
              subLabel={`(${
                currentMonth >= 12 ? currentYear + 1 : currentYear
              })`}
              value={formatCurrency(props.unitData.service_charge)}
            />
            <UnitDetailsRow
              label={"Insurance"}
              subLabel={`(${
                currentMonth >= 12 ? currentYear + 1 : currentYear
              })`}
              value={formatCurrency(props.unitData.insurance)}
            />

            {props.isVerifiedUser && props.isInternal && (
              <>
                <Typography variant={"h6"}>
                  Business Plan Assumptions
                </Typography>
                <UnitDetailsRow
                  label={"Event"}
                  value={props.unitData.event_type}
                />
                <UnitDetailsRow
                  label={"Commencement Date"}
                  value={formatDate(props.unitData.lease_commencement_date)}
                />
                <UnitDetailsRow
                  label={"ERV"}
                  value={formatCurrency(props.unitData.erv)}
                />
                {!props.isStorage && (
                  <UnitDetailsRow
                    label="Turnover %"
                    value={formatPercent(props.unitData.turnover_percentage)}
                  />
                )}
              </>
            )}
          </Stack>
        </>
      )}
    </Stack>
  );
}

interface DropdownProps {
  label: string;
  value: string;
  options: string[];
  onChange: (event: any) => void;
}

const UnitDetailsDropDown = (props: DropdownProps) => {
  return (
    <Stack direction={"row"} justifyContent={""}>
      <Typography flex={1}>{props.label}</Typography>
      <FormControl fullWidth style={{ flex: 1 }}>
        <DropDown
          id={props.label.replaceAll(" ", "-") + "-select"}
          value={props.value}
          label={props.label}
          onChange={props.onChange}
          style={{ fontWeight: "bold" }}
        >
          {props.options.map((d) => (
            <MenuItem key={d} value={d}>
              {d}
            </MenuItem>
          ))}
        </DropDown>
      </FormControl>
    </Stack>
  );
};

interface RowProps {
  label: string;
  value: string;
  subLabel?: string;
}

const UnitDetailsRow = (props: RowProps) => {
  const noWrapStyle = {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  };
  const subLabel = props.subLabel || "";
  return (
    <Stack direction={"row"} justifyContent={""}>
      <Stack
        direction={"row"}
        gap={0.5}
        flex={1}
        justifyContent={"flex-start"}
        alignItems={"center"}
      >
        <Typography style={noWrapStyle}>{props.label}</Typography>
        <Typography style={{ ...noWrapStyle, fontSize: 10 }}>
          {subLabel}
        </Typography>
      </Stack>
      <Typography flex={1} style={noWrapStyle}>
        <b>{props.value}</b>
      </Typography>
    </Stack>
  );
};

const VerticalDivider = () => (
  <div style={{ borderLeft: "1px solid var(--mSU)" }} />
);
