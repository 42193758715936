import { useState } from 'react';
import Button from '../Button/Button';
import Dialog from '@mui/material/Dialog';
import Stack from '@mui/material/Stack';
import {ToggleButton, Typography} from "@mui/material";
import {colours} from "../../theme";
import "./DataExportOptions.css";
import CheckIcon from "@mui/icons-material/Check";
import {StackedLineChart} from "@mui/icons-material";

const DataExportOptions = ({ open, handleClose, handleClickDownload, isVerifiedUser }) => {

  const [headers, setHeaders] = useState(["unit_id", "unit_name", "tenant", "trading_as", "lease_expiry", "rent", "last_year_turnover", "total_area", "sales_density", "occupancy_cost_ratio", "end_date", "turnover"])

  const fields = {
    "Unit Details": [
      {name: "Unit ID", field: "unit_id"},
      {name: "Unit Name", field: "unit_name"},
      {name: "Tenant Name", field: "tenant"},
      {name: "Tenant Trading As", field: "trading_as"},
      {name: "Sales", field: "turnover"},
      {name: "Total Area", field: "total_area"},
      {name: "Sales Density", field: "sales_density"},
      {name: "OCR", field: "occupancy_cost_ratio"},
      {name: "RSR", field: "rent_to_sales_ratio"},
    ],
    "Lease Details": [
      {name: "Start Date", field: "start_date"},
      {name: "End Date", field: "end_date"},
      {name: "Rent", field: "rent"},
      {name: "Break Option", field: "break_option"},
      {name: "Break Option Type", field: "break_option_type"},
      {name: "Break Notice Date", field: "break_notice_date"},
      {name: "Outside 1954 Act", field: "outside1954_act"},
      {name: "Leasing Status", field: "leasingStatus"},
    ],
    "Occupational Costs": [
      {name: "Business Rates", field: "rates_payable"},
      {name: "Service Charge", field: "service_charge"},
      {name: "Insurance", field: "insurance"},
      {name: "Total Occupational Costs", field: "total_occupational_costs"},
    ],
    "Business Plan Assumptions": [
      {name: "Event Type", field: "event_type"},
      {name: "Commencement Date", field: "lease_commencement_date"},
      {name: "ERV", field: "erv"},
      {name: "Turnover %", field: "turnover_percentage"},
    ],
  }



  return (
    <Dialog
      className={"DataExportOptions"}
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth={"md"}
    >
      <Stack direction={"column"} spacing={2}>
        <Typography variant={"h4"}>Select additional fields</Typography>
        <Stack direction={"row"} spacing={2} justifyContent={"space-between"}>
          {Object.keys(fields).map(label => (
          <Stack key={label} direction={"column"} spacing={1} style={{ visibility: (!isVerifiedUser && label === "Business Plan Assumptions") ? "hidden" : "visible" }}>
            <Typography variant={"h3"} style={{ color: colours.red, fontWeight: "bold", marginBottom: 4 }}>{label}</Typography>
            {
              fields[label].map(d => (
                <ExportField key={d.field} name={d.name} selected={headers.indexOf(d.field) !== -1}
                             onClick={() => setHeaders(prevState => {
                               if (prevState.indexOf(d.field) === -1) {
                                 // add the field
                                 return [...prevState, d.field]
                               } else {
                                 // remove the field
                                 return [...prevState.filter(dd => dd !== d.field)]
                               }
                             })} />
              ))
            }
          </Stack>
        ))}
        </Stack>

        <Stack direction={"row-reverse"}>
          <Button label={"Export data"} onClick={() => handleClickDownload(headers)} style={{ width: 130 }} />
        </Stack>
      </Stack>
    </Dialog>
  )

}

const ExportField = ({ name, selected, onClick }) => {
  return (
    <Stack direction={"row"} spacing={1} alignItems={"center"} onClick={onClick}>
      <ToggleButton style={{ padding: 2, background: selected ? colours.red : colours.white }}
                    value="check"
                    selected={selected}
      >
        <CheckIcon fontSize={"small"} style={{ color: "#fff" }} />
      </ToggleButton>
      <Typography>{name}</Typography>
    </Stack>
  )
}

export default DataExportOptions;
