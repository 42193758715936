import { useState } from "react";
import * as d3 from "d3";
import { styled } from '@mui/material/styles';
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { formatCurrency, formatPercent, formatInt, formatDate } from "../../js/format";
import RemoveButton from "../RemoveButton/RemoveButton";
import ComparisonArrow from "../ComparisonArrow/ComparisonArrow";
import { colours } from "../../theme";
import "./Table.css";

const CustomTable = ({ rows, selectedRow, handleClickRow, handleRemove }) => {
  const [aggregationType, setAggregationType] = useState("average");

  const selectedRowData = rows.find(d => d.unit_id === selectedRow);

  const averageRent = d3.mean(rows, d => d.rent) || 0;
  const averageTurnover = d3.mean(rows, d => d.last_year_turnover) || 0;
  const averageArea = d3.mean(rows, d => d.total_area) || 0;

  // note: when calculating total (weighted average) sales density, only count the area when turnover is not null
  const totalTurnover = d3.sum(rows, d => d.last_year_turnover)
  const totalAreaExcludingNullTurnover = d3.sum(rows, d => d.last_year_turnover ? d.total_area : 0)
  const averageSalesDensity = totalTurnover / totalAreaExcludingNullTurnover || 0;

  // note: total OCR would be unrepresentative if we included the costs of units that have no turnover information
  const totalOccupancyCost = d3.sum(rows, d => d.last_year_turnover ? d.total_occupational_costs : 0)
  const averageOCR = totalOccupancyCost / totalTurnover || 0

  // Sum calculations
  const totalRent = d3.sum(rows, d => d.rent)
  const totalArea = d3.sum(rows, d => d.total_area)
  const totalSalesDensity = d3.sum(rows, d => d.sales_density)
  const totalOCR = d3.sum(rows, d => d.occupancy_cost_ratio);

  const handleComparisonCalculationChange = (event) => {
    setAggregationType(event.target.value);
  };

  return (
    <TableContainer className={"Table"}>
      <Table aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell style={{ width: 110 - 32 }}>Unit Name</StyledTableCell>
            <StyledTableCell style={{ width: 230 - 32 }}>Tenant Name</StyledTableCell>
            <StyledTableCell style={{ width: 150 - 32 }}>Lease Expiry</StyledTableCell>
            <StyledTableCell style={{ width: 150 - 32 }}>Rent</StyledTableCell>
            <StyledTableCell style={{ width: 150 - 32 }}>Sales</StyledTableCell>
            <StyledTableCell style={{ width: 110 - 32 }}>Area (sqft)</StyledTableCell>
            <StyledTableCell style={{ width: 110 - 32 }}>Sales Density</StyledTableCell>
            <StyledTableCell style={{ width: 110 - 32 }}>OCR</StyledTableCell>
            <StyledTableCell style={{ width: 60 - 32 }}></StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <StyledTableRow key={row.unit_id} className={"Table-row" + (row.unit_id === selectedRow ? " Table-row-selected" : "")} onClick={() => handleClickRow(row)}>
              <StyledTableCell component="th" scope="row">{row.unit_name}</StyledTableCell>
              <StyledTableCell>{row.trading_as}</StyledTableCell>
              <StyledTableCell>{formatDate(row.end_date)}
                {/*<ComparisonArrow subject={row} comparator={selectedRowData} field={"end_date"} />*/}
              </StyledTableCell>
              <StyledTableCell>{formatCurrency(row.rent)}
                {/*<ComparisonArrow subject={row} comparator={selectedRowData} field={"rent"} />*/}
              </StyledTableCell>
              <StyledTableCell>{formatCurrency(row.last_year_turnover)}
                {/*<ComparisonArrow subject={row} comparator={selectedRowData} field={"last_year_turnover"} />*/}
              </StyledTableCell>
              <StyledTableCell>{formatInt(row.total_area)}
                {/*<ComparisonArrow subject={row} comparator={selectedRowData} field={"total_area"} />*/}
              </StyledTableCell>
              <StyledTableCell>{formatCurrency(row.sales_density)}<ComparisonArrow subject={row} comparator={selectedRowData} field={"sales_density"} /></StyledTableCell>
              <StyledTableCell>{formatPercent(row.occupancy_cost_ratio)}<ComparisonArrow subject={row} comparator={selectedRowData} field={"occupancy_cost_ratio"} /></StyledTableCell>
              <StyledTableCell align="right">
                <RemoveButton onClick={(e) => {
                  e.stopPropagation();
                  handleRemove(row);
                }} />
              </StyledTableCell>
            </StyledTableRow>
          ))}
          <TotalsRow key={"totals"}>
            <StyledTableCell component="th">
              <FormControl variant="outlined" style={{ minWidth: 160 }}>
                <InputLabel>Aggregation</InputLabel>
                <Select
                  value={aggregationType}
                  onChange={handleComparisonCalculationChange}
                  label="Aggregation"
                >
                  <MenuItem value="average">Average</MenuItem>
                  <MenuItem value="sum">Total</MenuItem>
                </Select>
              </FormControl>
            </StyledTableCell>
            <StyledTableCell></StyledTableCell>
            <StyledTableCell></StyledTableCell>
            <StyledTableCell>
              {formatCurrency(aggregationType === "average" ? averageRent : totalRent)}
              {/*<ComparisonArrow subject={{ unit_id: null, rent: aggregationType === "average" ? averageRent : totalRent }} comparator={selectedRowData} field={"rent"} />*/}
            </StyledTableCell>
            <StyledTableCell>
              {formatCurrency(aggregationType === "average" ? averageTurnover : totalTurnover)}
              {/*<ComparisonArrow subject={{ unit_id: null, last_year_turnover: aggregationType === "average" ? averageTurnover : totalTurnover }} comparator={selectedRowData} field={"last_year_turnover"} />*/}
            </StyledTableCell>
            <StyledTableCell>
              {formatInt(aggregationType === "average" ? averageArea : totalArea)}
              {/*<ComparisonArrow subject={{ unit_id: null, total_area: aggregationType === "average" ? averageArea : totalArea }} comparator={selectedRowData} field={"total_area"} />*/}
            </StyledTableCell>
            <StyledTableCell>
              {formatCurrency(averageSalesDensity)}
              <ComparisonArrow subject={{ unit_id: null, sales_density: aggregationType === "average" ? averageSalesDensity : totalSalesDensity }} comparator={selectedRowData} field={"sales_density"} />
            </StyledTableCell>
            <StyledTableCell>
              {formatPercent(averageOCR)}
              <ComparisonArrow subject={{ unit_id: null, occupancy_cost_ratio: aggregationType === "average" ? averageOCR : totalOCR }} comparator={selectedRowData} field={"occupancy_cost_ratio"} />
            </StyledTableCell>
            <StyledTableCell></StyledTableCell>
          </TotalsRow>
        </TableBody>
      </Table>
    </TableContainer>
  );

}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  '& svg': {
    marginLeft: 6,
  },
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: colours.pink,
    color: colours.black,
    borderTop: "1px solid var(--grey2)"
  },
}));

const StyledTableRow = styled(TableRow)(({ theme },) => ({
  background: colours.white,
  '& td': {
  },
  '&:nth-of-type(odd)': {
    backgroundColor: colours.grey3,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const TotalsRow = styled(TableRow)(({ theme },) => ({
  background: colours.specialist,
  borderTop: "2px solid var(--blue)",
  '& th': {
    fontWeight: "bold",
  },
  '& td': {
    fontWeight: "bold",
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));


export default CustomTable;


