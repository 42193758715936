import * as d3 from "d3";
import {Stack, Paper, Typography} from "@mui/material";
import {formatCurrency, formatPercent, formatInt, NA} from "../../js/format";

const UnitAnalysis = ({ unit, allUnits }) => {
  if (!unit) {
    return (
      <Stack sx={{ height: 113, width: 1180 }} justifyContent={"center"} alignItems={"center"}>
        <Typography>Select a unit below to compare</Typography>
      </Stack>
    )
  }

  // todo: create custom reducer to reduce computation here
  const allUnitsInCategory = allUnits.filter(d => d.category === unit.category)
  const totalRent = d3.sum(allUnitsInCategory, d => d.rent)
  const totalTurnover = d3.sum(allUnitsInCategory, d => d.last_year_turnover)
  const totalArea = d3.sum(allUnitsInCategory, d => d.total_area)

  // note: when calculating total (weighted average) sales density, only count the area when turnover is not null
  const totalAreaExcludingNullTurnover = d3.sum(allUnitsInCategory, d => d.last_year_turnover ? d.total_area : 0)
  const totalSalesDensity = totalTurnover / totalAreaExcludingNullTurnover;

  // note: total OCR would be unrepresentative if we included the costs of units that have no turnover information
  const totalOccupancyCost = d3.sum(allUnitsInCategory, d => d.last_year_turnover ? d.total_occupational_costs : 0)
  const totalOCR = totalOccupancyCost / totalTurnover;

  return (
    <Paper sx={{ width: 1180 }} >
      <Stack direction={"row"} sx={{ m: 2 }} justifyContent={"space-between"} spacing={2} >
        <Stack direction={"column"} spacing={1} style={{ width: 200 }}>
          <Typography style={{ marginBottom: 8 }}>&nbsp;</Typography>
          <Typography variant={"h5"}>{unit.category} Total</Typography>
          <Typography variant={"h5"} sx={{ color: "var(--blue2)" }}>{unit.trading_as}</Typography>
        </Stack>

        <UnitAnalysisSection title={"Rent"} unitValue={formatCurrency(unit.rent)} totalValue={formatCurrency(totalRent)} ratio={unit.rent/totalRent} />
        <UnitAnalysisSection title={"Sales"} unitValue={formatCurrency(unit.last_year_turnover)} totalValue={formatCurrency(totalTurnover)} ratio={unit.last_year_turnover/totalTurnover} />
        <UnitAnalysisSection title={"Area (sqft)"} unitValue={formatInt(unit.total_area)} totalValue={formatInt(totalArea)} ratio={unit.total_area/totalArea} />
        <UnitAnalysisSection title={"Sales Density"} unitValue={formatCurrency(unit.sales_density)} totalValue={formatCurrency(totalSalesDensity)} ratio={unit.sales_density/totalSalesDensity} />
        <UnitAnalysisSection title={"OCR"} unitValue={formatPercent(unit.occupancy_cost_ratio)} totalValue={isNaN(totalOCR) ? NA : formatPercent(totalOCR)} ratio={unit.occupancy_cost_ratio/totalOCR} />
      </Stack>
    </Paper>
  )
}

const UnitAnalysisSection = ({ title, unitValue, totalValue, ratio }) => {
  return (
    <Stack direction={"column"} spacing={1} style={{ width: 200, borderLeft: "1px solid var(--grey3)", paddingLeft: 10 }}>
      <Typography style={{ marginBottom: 8 }}>{title}</Typography>
      <Stack direction={"row"} alignItems={"flex-end"} justifyContent={"space-between"}>
        <Stack direction={"column"} spacing={1}>
          <Typography variant={"h5"}>{totalValue}</Typography>
          <Typography variant={"h5"} sx={{ color: "var(--blue2)" }}>{unitValue}</Typography>
        </Stack>

        <Typography style={{ fontSize: 50, fontWeight: 200, color: "var(--blue2)", lineHeight: 0.85 }}>
          {isNaN(ratio) ? NA : formatPercent(ratio).replace("%", "")}
          {!isNaN(ratio) && <span style={{ fontSize: 30 }}>%</span>}
        </Typography>

      </Stack>
    </Stack>
  )
}

export default UnitAnalysis;
