import Stack from "@mui/material/Stack";
import Search from "../components/Search/Search";
import UnitAnalysis from "../components/UnitAnalysis/UnitAnalysis";
import Table from "../components/Table/Table";
import Button from "../components/Button/Button";
import DataExportOptions from "../components/DataExportOptions/DataExportOptions";
import PageContainer from "../components/PageContainer/PageContainer";
import { arrayToCSV, download } from "../js/dataExport";
import { logEvent } from "../js/interface";
import { useState } from "react";
import type { LeasingScheduleUnit } from "../types/LeasingScheduleUnit";

export interface UnitComparisonProps {
  units: LeasingScheduleUnit[];
  handleSearchSelectUnit: (e: any, unit: any) => void;
  handleClickCompareUnit: (unitId: number) => void;
  comparisonUnits: number[];
  handleSearchSelectCategory: (e: any, category: any, remove: any) => void;
  selectedUnit: number;
  handleClickUnit: (unitId: number) => void;
  setComparisonUnits: React.Dispatch<React.SetStateAction<number[]>>;
  isVerifiedUser: boolean;
}

export default function UnitComparison(props: UnitComparisonProps) {
  const [dataExportDialogOpen, setDataExportDialogOpen] = useState(false);

  const handleClickDownload = (headers: string[]) => {
    download("Unit Data.csv",
      arrayToCSV(
        props.units.filter((d) => props.comparisonUnits.indexOf(d.unit_id) !== -1),
        headers
      )
    );
    logEvent({ eventName: "downloadComparisonTable" });
  };


  return (
    <PageContainer>
      <Stack
        direction={"column"}
        justifyContent={"center"}
        alignItems={"center"}
        spacing={2}
      >
        <div style={{ padding: 10 }}>
          <Search
            unitsData={props.units}
            handleSearchSelectUnit={props.handleSearchSelectUnit}
            handleClickCompareUnit={props.handleClickCompareUnit}
            comparisonUnits={props.comparisonUnits}
            includeCategories={true}
            handleSearchSelectCategory={props.handleSearchSelectCategory}
          />
        </div>
        <UnitAnalysis
          unit={props.units.find((d) => d.unit_id === props.selectedUnit)}
          allUnits={props.units}
        />
        <Table
          rows={props.units.filter(
            (d) => props.comparisonUnits.indexOf(d.unit_id) !== -1
          )}
          selectedRow={props.selectedUnit}
          handleClickRow={(d: any) =>
            props.handleClickUnit(
              props.selectedUnit === d.unit_id ? null : d.unit_id
            )
          }
          handleRemove={(row: any) => props.handleClickCompareUnit(row.unit_id)}
        />
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          style={{ width: "100%" }}
        >
          <Button
            label={"Export data"}
            disabled={props.comparisonUnits.length === 0}
            onClick={() => {
              setDataExportDialogOpen(true);
            }}
            variant={undefined}
          />
          <Button
            label={"Clear all"}
            variant={"outline"}
            onClick={() => props.setComparisonUnits([])}
            disabled={false}
          />
        </Stack>
        <DataExportOptions
          open={dataExportDialogOpen}
          isVerifiedUser={props.isVerifiedUser}
          handleClose={() => setDataExportDialogOpen(false)}
          handleClickDownload={handleClickDownload}
        />
      </Stack>
    </PageContainer>
  );
}
