import { Stack, Typography } from "@mui/material";
import InternalExternalToggle from "../InternalExternalToggle/InternalExternalToggle";
import Profile from "../Profile/Profile";
import Tab from "../Tab/Tab";
import TCLogo from "../../images/TCLogo.png"

export interface AppBarProps {
  isSignedIn: boolean;
  isInternal: boolean;
  setIsInternal: React.Dispatch<React.SetStateAction<boolean>>;
  userName: string;
  page: "map" | "comparison";
  setPage: React.Dispatch<React.SetStateAction<string>>;
  comparisonUnits: number[];
}

export default function AppBar(props: AppBarProps) {
  return (
    <Stack
      direction={"row"}
      justifyContent={"space-between"}
      alignItems={"center"}
      style={{
        position: "relative",
        width: "100%",
        background: "var(--themeColour)",
        height: "70px",
      }}
    >
      <img
        alt={"The Trafford Centre Logo"}
        src={TCLogo}
        style={{ margin: 20, width: 150 }}
      />
      {props.isSignedIn && (
        <Stack
          direction={"row"}
          spacing={2}
          alignItems={"center"}
          sx={{ mr: 1 }}
        >
          {props.isInternal && (
            <Typography style={{ color: "white", fontWeight: "bold" }}>
              Internal use
            </Typography>
          )}
          <InternalExternalToggle
            isInternal={props.isInternal}
            setIsInternal={props.setIsInternal}
          />
          <Profile userName={props.userName} />
        </Stack>
      )}
      {props.isSignedIn && props.isInternal && (
        <Stack
          direction={"row"}
          style={{ position: "absolute", top: 20, left: 230 }}
          spacing={1}
        >
          <Tab
            label={"Unit Map"}
            selected={props.page === "map"}
            counter={0}
            onClick={() => props.setPage("map")}
          />
          <Tab
            label={"Unit Comparison"}
            selected={props.page === "comparison"}
            counter={props.comparisonUnits.length}
            onClick={() => props.setPage("comparison")}
          />
        </Stack>
      )}
    </Stack>
  );
}
