

const labelOrientations = {
  // lower floor
  // middle vertical ones
  2200: -90,
  2250: -90,
  2300: -90,
  2350: -90,
  2400: -90,
  2450: -90,
  2500: -90,
  2550: -90,
  2600: -90,
  2650: -90,
  2700: -90,
  2750: -90,
  2800: -90,

  // right side
  2850: -58,
  2900: -58,
  2950: -58,

  // right side - lower
  3650: -58,
  3600: -58,
  3550: -58,
  3500: -58,

  4200: -90,
  4250: -90,

  3850: -90,
  3950: -90,
  4000: -90,
  4050: -90,
  4125: -90,

  6300: -14, // mall cafe 2

  // left side
  1700: 58,
  1750: 58,
  1800: 58,
  4400: 85,
  4450: 75,
  4500: 65,
  4600: 58.5,
  4550: 58.5,
  4650: 58,
  4700: 58,
  4750: 58,
  4800: 58,
  5400: 58,
  4850: 58,
  4900: 58,
  4950: 58,
  5000: 58,

  11450: -90,
  11500: -90,
  11550: -90,
  11600: -90,
  11650: -90,
  11725: -90,
  5500: -90,
  11800: -90,
  11850: -90,

  13800: -90,
  13750: -90,
  13700: -90,
  13650: -90,
  13600: -90,
  13550: -90,
  13500: -90,
  13450: -90,

  13150: -90,
  13100: -90,
  13050: -90,
  13000: -90,
  12950: -90,
  12900: -90,

  12750: -58.5,

  12250: -58.5,
  12300: -58.5,

  12550: -58.5,
  12500: -58.5,
  12450: -58.5,
  5650: -58.5,

  11900: -90,
  11950: -90,

  11250: 59,

  12000: -90,
  12050: -90,

  12200: -90,

  14500: -58.5,
  14450: -58.5,
  14400: -58.5,
  14300: 58,
  14250: 58,
  14200: 58,
  14150: 58,
  14100: 58,
  14050: 58,
  14000: 58,
  13950: 58,

  13400: -90,
  13350: -90,
  13300: -90,
  13250: -90,
  13200: -90,

}

export default labelOrientations;