import {useState} from "react";
import {Radio, RadioGroup, FormLabel, FormControlLabel, FormControl, Typography, Stack} from '@mui/material'
import {styled} from "@mui/material/styles";
import {colours} from "../../theme";
import {logEvent} from "../../js/interface";
import {turnoverOverlayToLabelMap} from "../../js/turnover";
import {DropDownOutlined} from "../DropDown/DropDown";
import MenuItem from "@mui/material/MenuItem";
import {dataOverlayToLabelMap} from "../../js/colourUtils";

const OverlaySelect = ({ onChange, isInternal }) => {

  const [value, setValue] = useState('category');

  const handleChange = (event) => {
    onChange(event.target.value)
    setValue(event.target.value)
    logEvent({ eventName: "changeOverlay", overlay: event.target.value })
  };

  const labels = { ...dataOverlayToLabelMap }
  delete labels.category // this is required to insert 'Data Overlay' after 'Category'

  const optionToLabelMap = { none: "None", category: "Category", d: "Data Overlay", ...dataOverlayToLabelMap, t: "Trading Analysis", ...turnoverOverlayToLabelMap}
  const options = isInternal ? Object.keys(optionToLabelMap) : ["none", "category"]


  return (
    <FormControl>
      <FormLabel id="overlay-select">
        <Typography style={{ color: "var(--black)", marginBottom: 8 }} variant="h5">Overlay</Typography>
      </FormLabel>
      <DropDownOutlined
        id="overlay-select"
        label="overlay-select"
        value={value}
        onChange={handleChange}
      >
        {options.map(d => (
          (d === "d" || d === "t") ? <MenuItem key={d} value={d} style={{ pointerEvents: "none" }}><b>{optionToLabelMap[d]}</b></MenuItem>
            : <MenuItem key={d} value={d}>{optionToLabelMap[d]}</MenuItem>
        ))}
      </DropDownOutlined>
    </FormControl>
  )
}


// Legacy version uses radio buttons
const LegacyOverlaySelect = ({ onChange, isInternal }) => {

  const [value, setValue] = useState('category');

  const handleChange = (event) => {
    onChange(event.target.value)
    setValue(event.target.value)
    logEvent({ eventName: "changeOverlay", overlay: event.target.value })
  };

  return (
    <FormControl>
      <FormLabel id="overlay-select">
        <Typography style={{ color: "var(--black)", marginBottom: 8 }} variant="h5">Data Overlay</Typography>
      </FormLabel>
      <RadioGroup
        aria-labelledby="overlay-select"
        name="category-select"
        value={value}
        onChange={handleChange}
      >
        <FormControlLabel value="none" control={<StyledRadio icon={<RadioIcon />} checkedIcon={<RadioIconChecked />} />} label="None" />
        <FormControlLabel value="category" control={<StyledRadio icon={<RadioIcon />} checkedIcon={<RadioIconChecked />} />} label="Category" />
        {isInternal && <FormControlLabel value="leasing_status" control={<StyledRadio icon={<RadioIcon />} checkedIcon={<RadioIconChecked />} />} label="Leasing Status" />}
        {isInternal && <FormControlLabel value="turnover" control={<StyledRadio icon={<RadioIcon />} checkedIcon={<RadioIconChecked />} />} label="Turnover" />}
        {isInternal && <FormControlLabel value="sales_density" control={<StyledRadio icon={<RadioIcon />} checkedIcon={<RadioIconChecked />} />} label="Sales Density" />}
        {isInternal && <FormControlLabel value="rsr" control={<StyledRadio icon={<RadioIcon />} checkedIcon={<RadioIconChecked />} />} label="RSR" />}
        {isInternal && <FormControlLabel value="ocr" control={<StyledRadio icon={<RadioIcon />} checkedIcon={<RadioIconChecked />} />} label="OCR" />}
        {isInternal && <FormControlLabel value="lease_expiry" control={<StyledRadio icon={<RadioIcon />} checkedIcon={<RadioIconChecked />} />} label="Lease Expiry" />}
        {isInternal && <FormControlLabel value="break_option" control={<StyledRadio icon={<RadioIcon />} checkedIcon={<RadioIconChecked />} />} label="Break Option" />}
        {isInternal && Object.keys(turnoverOverlayToLabelMap).map(id => (
          <FormControlLabel key={id} value={id} control={<StyledRadio icon={<RadioIcon />} checkedIcon={<RadioIconChecked />} />} label={turnoverOverlayToLabelMap[id]} />
        ))}
      </RadioGroup>
    </FormControl>
  )
}


const RadioIcon = styled('span')(({ theme }) => ({
  borderRadius: '50%',
  width: 23,
  height: 23,
  boxShadow:
    theme.palette.mode === 'dark'
      ? '0 0 0 1px rgb(16 22 26 / 40%)'
      : 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
  backgroundColor: theme.palette.mode === 'dark' ? '#394b59' : '#f5f8fa',
  '.Mui-focusVisible &': {
    outline: '2px auto rgba(19,124,189,.6)',
    outlineOffset: 2,
  },
  'input:hover ~ &': {
    backgroundColor: theme.palette.mode === 'dark' ? '#30404d' : '#ebf1f5',
  },
  'input:disabled ~ &': {
    boxShadow: 'none',
    background:
      theme.palette.mode === 'dark' ? 'rgba(57,75,89,.5)' : 'rgba(206,217,224,.5)',
  },
}));

const RadioIconChecked = styled(RadioIcon)({
  backgroundColor: colours.red,
  '&:before': {
    display: 'block',
    width: 23,
    height: 23,
    backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
    content: '""',
  },
  'input:hover ~ &': {
    backgroundColor: colours.red,
  },
});


const StyledRadio = styled(Radio)({
  padding: "4px 9px 4px 9px"
})


export default OverlaySelect;
