import {styled} from "@mui/material/styles";
import Select from "@mui/material/Select";

const DropDown = styled(Select)({
  "& .MuiSelect-select": {
    padding: 0,
  },
  "& .MuiOutlinedInput-input": {
    border: "none",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    border: "none",
  },
})

const DropDownOutlined = styled(DropDown)({
  "& .MuiOutlinedInput-input": {
    border: "1px solid var(--grey4)",
    borderRadius: 20,
    padding: 10,
    paddingLeft: 15,
  },
  "& .MuiOutlinedInput-input:focus": {
    borderRadius: 20,
  },
})


export default DropDown;

export { DropDownOutlined }
