import {useState, useEffect} from "react";
import TextArea from "../TextArea/TextArea";
import {Typography} from "@mui/material";
import { Send } from "@mui/icons-material"
import {post} from "../../js/interface";
import useFirebaseAuth from "../../hooks/useFirebaseAuth";
import {colours} from "../../theme";
import "./NotesEditor.css";

/**
 * Simple plain text editor for notes. Notes can't be submitted if the app is offline.
 * @param unitId
 * @param handleRefreshNotesForUnit
 * @param disabled: If true, then editing is disabled
 */
const NotesEditor = ({ unitId, handleRefreshNotesForUnit, handleError }) => {

  // detect whether the app is offline so that we can prevent form submit
  const [online, setOnline] = useState(window.navigator.onLine)
  const auth = useFirebaseAuth(online)

  useEffect(() => {
    const handleAppOnline = () => setOnline(true)
    window.addEventListener("online", handleAppOnline);

    // cleanup this component
    return () => {
      window.removeEventListener("online", handleAppOnline);
    };
  }, []);

  useEffect(() => {
    const handleAppOffline = () => setOnline(false)
    window.addEventListener("offline", handleAppOffline);

    // cleanup this component
    return () => {
      window.removeEventListener("offline", handleAppOffline);
    };
  }, []);


  const handleSaveNote = (event) => {
    event.preventDefault()

    if (!auth.token || !auth.user) return;

    const form = event.target
    const formData = new FormData(form);

    const formJson = Object.fromEntries(formData.entries());
    console.log(formJson)

    const noteData = {
      createdBy: auth.user.email,
      unitId: unitId,
      accessLevel: "everyone",
      highPriority: false,
      body: formJson.noteBody,
    }

    post("/notes", noteData, auth.token, newNote => {
      console.log("created new note with ID", newNote.id)
      handleRefreshNotesForUnit(unitId)
    }, (error) => {
      handleError(error)
    })
  }

  return (
    <form id={"notes-editor"} className={"NotesEditor"}
          onSubmit={(e) => handleSaveNote(e)}>
      <TextArea id={"notes-editor-textarea"} label={"noteBody"} name={"noteBody"} />
      <div className={"NotesEditor-controls"}>
        {!online && <Typography style={{color: colours.red}}>offline</Typography>}
        <button disabled={!online} type={"submit"}>
          <Send />
        </button>
      </div>
    </form>
  )
}

export default NotesEditor;
