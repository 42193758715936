import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import type { LeasingScheduleUnit } from "../../types/LeasingScheduleUnit";
import { formatCurrency, formatDate } from "../../js/format";
import Typography from "@mui/material/Typography";

export interface OtherUnitsTableProps {
  units: LeasingScheduleUnit[];
  handleClickUnit: (unitId: number) => void;
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    color: theme.palette.primary.main,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

export default function OtherUnitsTable(props: OtherUnitsTableProps) {
  const handleClickRow = (unit: LeasingScheduleUnit) => {
    props.handleClickUnit(unit.unit_id);
  };

  return (
    <Box sx={{ width: "100%", overflow: "hidden" }}>
      <TableContainer sx={{ maxHeight: 264, mt: 1 }}>
        <Table
          stickyHeader
          sx={{ minWidth: 650 }}
          aria-label="trading-as-table"
        >
          <TableHead>
            <TableRow>
              <StyledTableCell>
                <Typography variant={"h6"}>ID</Typography>
              </StyledTableCell>
              <StyledTableCell>
                <Typography variant={"h6"}>Demise</Typography>
              </StyledTableCell>
              <StyledTableCell align="right">
                <Typography variant={"h6"}>Size (sqft)</Typography>
              </StyledTableCell>
              <StyledTableCell align="right">
                <Typography variant={"h6"}>Rent</Typography>
              </StyledTableCell>
              <StyledTableCell align="right">
                <Typography variant={"h6"}>Rent (per sqft)</Typography>
              </StyledTableCell>
              <StyledTableCell align="right">
                <Typography variant={"h6"}>Lease Expiry</Typography>
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.units.map((unit) => (
              <TableRow
                hover
                key={unit.unit_id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                onClick={() => handleClickRow(unit)}
              >
                <StyledTableCell component="th" scope="unit">
                  {unit.parsed_unit_id}
                </StyledTableCell>
                <StyledTableCell>{unit.demise}</StyledTableCell>
                <StyledTableCell align="right">
                  {isNaN(unit.total_area) ? "-" : unit.total_area}
                </StyledTableCell>
                <StyledTableCell align="right">
                  {formatCurrency(unit.rent)}
                </StyledTableCell>
                <StyledTableCell align="right">
                  {formatCurrency(unit.rent_psf)}
                </StyledTableCell>
                <StyledTableCell align="right">
                  {formatDate(unit.lease_expiry)}
                </StyledTableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
