import * as d3 from "d3";
import firebase from 'firebase/compat/app';

const BASE_URL = window.location.hostname === "localhost" ? "https://tc-unit-comparator-dev-bd97.nw.r.appspot.com/api"
// const BASE_URL = window.location.hostname === "localhost" ? "http://127.0.0.1:5000/api"  // yarn serve; use cooleh
  : window.location.hostname === '0.0.0.0' ? "/api"  // local API serving local build of app
  : `https://${window.location.hostname}/api`

const parseResponse = res => {
  if (res.status >= 300) {
    console.log("Catching >= 300 response", res)
    throw new Error("Received an error from the server: " + res.statusText, { cause: "Bad response from " + res.url })
  } else {
    return res.json()
  }
}

export const get = (path, token, callback, onError) => {
  if (navigator.onLine) {
    return fetch(BASE_URL + path, {
      headers: new Headers({
        "Authorization": `Bearer ${token}`
      }),
    })
      .then(parseResponse)
      .then(callback)
      .catch(onError)
  } else {
    return onError(new Error("No internet connection"))
  }
}

export const post = (path, data, token, callback, onError) => {
  if (navigator.onLine) {

    return fetch(BASE_URL + path, {
      method: "POST",
      headers: new Headers({
        "Authorization": `Bearer ${token}`,
        "Content-Type": "application/json",
      }),
      body: JSON.stringify(data)
    })
      .then(parseResponse)
      .then(callback)
      .catch(onError)
  } else {
    return onError(new Error("No internet connection"))
  }
}


export const getCSV = (path, token, callback, onError) => {

  if (!token) {
    return;
  }

  return d3.csv(BASE_URL + path, {
    headers: new Headers({
      "Authorization": `Bearer ${token}`
    }),
  })
    .then(callback)
    .catch(onError)
}

export const delete_ = (path, token, callback, onError) => {
  return fetch(BASE_URL + path, {
    method: "DELETE",
    headers: new Headers({
      "Authorization": `Bearer ${token}`,
    }),
  })
    .then(parseResponse)
    .then(callback)
    .catch(onError)
}

export const logEvent = (eventData) => {
  let token = ""
  try {
    // this will not work if user isn't signed in
    token = firebase.auth().currentUser.auth.currentUser.accessToken
  } catch {
    // pass
  }
  return post("/analytics", eventData, token, () => null, () => null)
}
