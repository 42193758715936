import {Stack} from "@mui/material";
import "./ZoomControls.css"

const ZoomControls = ({ zoomInButtonRef, zoomResetButtonRef, zoomOutButtonRef }) => {
  return (
    <Stack className={"ZoomControls"} direction={"column"} spacing={"1px"}>
      <button className={"ZoomControls-zoomIn"} ref={zoomInButtonRef}>
        <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M14 9.1282H9V14.1282H5V9.1282H0V5.1282H5V0.128204H9V5.1282H14V9.1282Z"/>
        </svg>
      </button>
      <button className={"ZoomControls-zoomReset"} ref={zoomResetButtonRef}>
        <svg width="20" height="17" viewBox="0 0 20 17" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M8 17V11H12V17H17V9H20L10 0L0 9H3V17H8Z"/>
        </svg>
      </button>
      <button className={"ZoomControls-zoomOut"} ref={zoomOutButtonRef}>
        <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M14 9H0V5H14V9Z"/>
        </svg>

      </button>
    </Stack>
  )
}

export default ZoomControls;
