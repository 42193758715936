import {Stack} from "@mui/material";
import "./InternalExternalToggle.css"
import {logEvent} from "../../js/interface";

const InternalExternalToggle = ({ isInternal, setIsInternal }) => {

  return (
    <Stack direction={"row"} alignItems={"center"}>
      <Stack className={"InternalExternal-button InternalExternal-button-external" + (!isInternal ? " InternalExternal-button-selected" : "")} alignItems={"center"} justifyContent={"center"}
             onClick={() => {
               logEvent({ eventName: isInternal ? "setExternal" : "setInternal" })
               setIsInternal(!isInternal)
             }}>
        <svg className={"InternalExternal-icon"} width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clipPath="url(#clip0_689_8707)">
            <path d="M14.9902 5C9.47023 5 4.99023 9.48 4.99023 15C4.99023 20.52 9.47023 25 14.9902 25C20.5102 25 24.9902 20.52 24.9902 15C24.9902 9.48 20.5102 5 14.9902 5ZM18.6002 11.34C19.6702 11.34 20.5302 12.2 20.5302 13.27C20.5302 14.34 19.6702 15.2 18.6002 15.2C17.5302 15.2 16.6702 14.34 16.6702 13.27C16.6602 12.2 17.5302 11.34 18.6002 11.34ZM12.6002 9.76C13.9002 9.76 14.9602 10.82 14.9602 12.12C14.9602 13.42 13.9002 14.48 12.6002 14.48C11.3002 14.48 10.2402 13.42 10.2402 12.12C10.2402 10.81 11.2902 9.76 12.6002 9.76ZM12.6002 18.89V22.64C10.2002 21.89 8.30023 20.04 7.46023 17.68C8.51023 16.56 11.1302 15.99 12.6002 15.99C13.1302 15.99 13.8002 16.07 14.5002 16.21C12.8602 17.08 12.6002 18.23 12.6002 18.89ZM14.9902 23C14.7202 23 14.4602 22.99 14.2002 22.96V18.89C14.2002 17.47 17.1402 16.76 18.6002 16.76C19.6702 16.76 21.5202 17.15 22.4402 17.91C21.2702 20.88 18.3802 23 14.9902 23Z" fill="black"/>
          </g>
          <defs>
            <clipPath id="clip0_689_8707">
              <rect width="30" height="30" fill="white"/>
            </clipPath>
          </defs>
        </svg>
      </Stack>
      <Stack className={"InternalExternal-button InternalExternal-button-internal" + (isInternal ? " InternalExternal-button-selected" : "")} alignItems={"center"} justifyContent={"center"}
             onClick={() => {
               logEvent({ eventName: isInternal ? "setExternal" : "setInternal" })
               setIsInternal(!isInternal)
             }}>
        <svg className={"InternalExternal-icon"} width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clipPath="url(#clip0_689_8706)">
            <path d="M12 14.75C11.31 14.75 10.75 15.31 10.75 16C10.75 16.69 11.31 17.25 12 17.25C12.69 17.25 13.25 16.69 13.25 16C13.25 15.31 12.69 14.75 12 14.75ZM18 14.75C17.31 14.75 16.75 15.31 16.75 16C16.75 16.69 17.31 17.25 18 17.25C18.69 17.25 19.25 16.69 19.25 16C19.25 15.31 18.69 14.75 18 14.75ZM15 5C9.48 5 5 9.48 5 15C5 20.52 9.48 25 15 25C20.52 25 25 20.52 25 15C25 9.48 20.52 5 15 5ZM15 23C10.59 23 7 19.41 7 15C7 14.71 7.02 14.42 7.05 14.14C9.41 13.09 11.28 11.16 12.26 8.77C14.07 11.33 17.05 13 20.42 13C21.2 13 21.95 12.91 22.67 12.74C22.88 13.45 23 14.21 23 15C23 19.41 19.41 23 15 23Z" fill="black"/>
          </g>
          <defs>
            <clipPath id="clip0_689_8706">
              <rect width="30" height="30" fill="white"/>
            </clipPath>
          </defs>
        </svg>
      </Stack>
    </Stack>
  )
}

export default InternalExternalToggle;
