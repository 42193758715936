
/*

  Calculations relating to monthly turnover data

*/


export const turnoverOverlayToLabelMap = {
  "mat_vs_category": "MAT Growth vs Category",
  "mat_vs_category_monthly": "Monthly Growth vs Category",
  "mat_growth": "MAT YOY%",
  "mat_growth_monthly": "Monthly YOY%",
  "mat_density": "MAT Sales Density",
  "mat_density_monthly": "Monthly Sales Density",
  "mat_ocr": "MAT-based OCR",
}

export const turnoverOverlayToDescriptionMap = {
  "mat_vs_category": "Moving annual total (MAT) turnover growth from the previous 12 months to the last 12 months vs the category average MAT growth in the same time period. Units that do not have 24 months of turnover data are not included.",
  "mat_vs_category_monthly": "Turnover growth to the selected month and year from 1 year previous.",
  "mat_growth": "MAT Growth",
  "mat_growth_monthly": "Monthly Growth",
  "mat_density": "MAT Density",
  "mat_density_monthly": "Monthly Density",
  "mat_ocr": "MAT-based OCR",
}


/**
 * Calculate all MAT fields for a given data set. This function is used for both category average and unit calculations
 * Multiple calcs are done at the same time to avoid repeating the same filters.
 * If any of the values are null, then we must return null, which makes this harder.
 * @param data array of turnoverData rows
 * @param selectedYear integer
 * @param selectedMonth integer
 */
export const calculateMAT = (data, selectedYear, selectedMonth) => {

  if (data.length === 0) return {};

  const selectedDate = selectedYear * 12 + selectedMonth;
  const _12MonthsAgo = (selectedYear - 1) * 12 + selectedMonth;
  const _24MonthsAgo = (selectedYear - 2) * 12 + selectedMonth;

  // use a custom reducer to calculate multiple fields at the same time and avoid doing the same filters
  let values = data.reduce((a, dd) => {
    // if value is in date range
    const date = dd.year * 12 + dd.month
    if (date > _12MonthsAgo && date <= selectedDate) {
      // logic: sum values even if there are nulls (all nulls are converted to 0 on load)
      // if (!dd.turnover) {
      //   return a
      // } else {
        return { ...a, last12MonthsMAT: a.last12MonthsMAT + dd.turnover, last12MonthsMATMonths: a.last12MonthsMATMonths + 1 }
      // }
    }
    else if (date > _24MonthsAgo && date <= _12MonthsAgo) {
      // if (!dd.turnover) {
      //   return a
      // } else {
        return { ...a, previous12MonthsMAT: a.previous12MonthsMAT + dd.turnover, previous12MonthsMATMonths: a.previous12MonthsMATMonths + 1 }
      // }
    } else {
      return a
    }
  }, { last12MonthsMAT: 0, last12MonthsMATMonths: 0, previous12MonthsMAT: 0, previous12MonthsMATMonths: 0})

  if (values.last12MonthsMATMonths < 12) {
    values.last12MonthsMAT = null;
  }
  if (values.previous12MonthsMATMonths < 12) {
    values.previous12MonthsMAT = null;
  }

  values.selectedMonthTurnover = data.reduce((total, dd) => {
    if (dd.year === selectedYear && dd.month === selectedMonth) {
      return total + dd.turnover
    } else {
      return total
    }
  }, 0)

  values.selectedMonthPreviousYearTurnover = data.reduce((total, dd) => {
    if (dd.year === selectedYear - 1 && dd.month === selectedMonth) {
      return total + dd.turnover
    } else {
      return total
    }
  }, 0)

  values.selectedMonth = selectedMonth;
  values.selectedYear = selectedYear;

  // 4 - mat_growth
  // Unit MAT growth = (Last 12 months MAT – Previous 12 months MAT) / Previous 12 months MAT
  if (!values.last12MonthsMAT || !values.previous12MonthsMAT) {
    values.MATGrowth = null
  } else {
    values.MATGrowth = (values.last12MonthsMAT - values.previous12MonthsMAT) / values.previous12MonthsMAT
  }

  // 4 - mat_growth_monthly
  // Calculation: (Unit monthly turnover[Month X this year] – Unit turnover[month X of last year] ) / Unit turnover[month X of last year]
  if (!values.selectedMonthTurnover || !values.selectedMonthPreviousYearTurnover) {
    values.MATGrowthMonthly = null
  } else {
    values.MATGrowthMonthly = (values.selectedMonthTurnover - values.selectedMonthPreviousYearTurnover) / values.selectedMonthPreviousYearTurnover
  }

  return values
}

/**
 * Calculate all category averages for the required MAT fields. This uses the return value of the above function except
 * with unit categories joined into each row.
 * @param categories list of category labels (strings)
 * @param turnoverValuesWithCategory
 */
export const calculateCategoryMAT = (categories, turnoverValuesWithCategory) => {
  const matCalcs = categories.map(category => {

    const categoryData = turnoverValuesWithCategory.filter(dd => dd.category === category)

    const values = categoryData.reduce((a, dd) => {
      if (dd.last12MonthsMAT) {  // don't count units that don't have a full 12 months
        a = { ...a, last12MonthsMAT: a.last12MonthsMAT + dd.last12MonthsMAT, last12MonthsMATNUnits: a.last12MonthsMATNUnits + 1 }
      }
      if (dd.previous12MonthsMAT) {
        a = { ...a, previous12MonthsMAT: a.previous12MonthsMAT + dd.previous12MonthsMAT, previous12MonthsMATNUnits: a.previous12MonthsMATNUnits + 1 }
      }
      if (dd.selectedMonthTurnover) {
        a = { ...a, selectedMonthTurnover: a.selectedMonthTurnover + dd.selectedMonthTurnover, selectedMonthTurnoverNUnits: a.selectedMonthTurnoverNUnits + 1 }
      }
      if (dd.selectedMonthPreviousYearTurnover) {
        a = { ...a, selectedMonthPreviousYearTurnover: a.selectedMonthPreviousYearTurnover + dd.selectedMonthPreviousYearTurnover, selectedMonthPreviousYearTurnoverNUnits: a.selectedMonthPreviousYearTurnoverNUnits + 1 }
      }
      return a
    }, { last12MonthsMAT: 0, last12MonthsMATNUnits: 0, previous12MonthsMAT: 0, previous12MonthsMATNUnits: 0, selectedMonthTurnover: 0, selectedMonthTurnoverNUnits: 0, selectedMonthPreviousYearTurnover: 0, selectedMonthPreviousYearTurnoverNUnits: 0 })

    if (values.last12MonthsMATNUnits === 0) {
      values.last12MonthsMAT = null
    } else {
      values.last12MonthsMAT = values.last12MonthsMAT / values.last12MonthsMATNUnits
    }

    if (values.previous12MonthsMATNUnits === 0) {
      values.previous12MonthsMAT = null
    } else {
      values.previous12MonthsMAT = values.previous12MonthsMAT / values.previous12MonthsMATNUnits
    }

    if (values.selectedMonthTurnoverNUnits === 0) {
      values.selectedMonthTurnover = null
    } else {
      values.selectedMonthTurnover = values.selectedMonthTurnover / values.selectedMonthTurnoverNUnits
    }

    if (values.selectedMonthPreviousYearTurnoverNUnits === 0) {
      values.selectedMonthPreviousYearTurnover = null
    } else {
      values.selectedMonthPreviousYearTurnover = values.selectedMonthPreviousYearTurnover / values.selectedMonthPreviousYearTurnoverNUnits
    }

    // 4 - mat_growth
    // Unit MAT growth = (Last 12 months MAT – Previous 12 months MAT) / Previous 12 months MAT
    values.MATGrowth = (values.last12MonthsMAT - values.previous12MonthsMAT) / values.previous12MonthsMAT

    // 4 - mat_growth_monthly
    // Calculation: (Unit monthly turnover[Month X this year] – Unit turnover[month X of last year] ) / Unit turnover[month X of last year]
    values.MATGrowthMonthly = (values.selectedMonthTurnover - values.selectedMonthPreviousYearTurnover) / values.selectedMonthPreviousYearTurnover

    return { category, ...values }
  })

  return matCalcs
}