import EditNoteIcon from '@mui/icons-material/EditNote';
import UnitDetailsIconButton from "./UnitDetailsIconButton";

export interface StorageUnitsButtonProps {
  active: boolean;
  notesCount: number;
  onClick: () => void;
}

export default function StorageUnitsButton(props: StorageUnitsButtonProps) {
  return (
    <UnitDetailsIconButton
      active={props.active}
      badgeCount={props.notesCount}
      onClick={props.onClick}
      tooltip="Notes"
    >
      <EditNoteIcon sx={{ height: 24, width: 24 }} />
    </UnitDetailsIconButton>
  );
}
