import * as React from "react";
import {FormControl, FormLabel, Stack, Typography} from "@mui/material";
import {DropDownOutlined} from "../DropDown/DropDown";
import MenuItem from "@mui/material/MenuItem";
import {months} from "../../js/format"
import {turnoverOverlayToLabelMap} from "../../js/turnover";

const shortMonths = months.map(d => d.slice(0, 3));
const currentYear = (new Date()).getFullYear();
// note: Date.getMonth() returns month number - 1 (i.e. Jan is 0), therefore current month is (this value + 1)
const currentMonth = (new Date()).getMonth() + 1;

const DateSelect = ({dataOverlay, selectedMonth, setSelectedMonth, selectedYear, setSelectedYear}) => {

  // only render for MAT turnover overlays
  // if (Object.keys(turnoverOverlayToLabelMap).indexOf(dataOverlay) === -1) return;

  // show all years from today back to 2021
  const yearOptions = []
  for (let i = 2021; i <= currentYear; i++) {
    yearOptions.push(i)
  }

  // don't show months in the future
  let monthOptions = shortMonths;
  if (selectedYear === currentYear) {
    monthOptions = monthOptions.filter((d, i) => (i + 1) <= currentMonth)
  }
  if (selectedMonth > monthOptions.length) {
    setSelectedMonth(monthOptions.length)
  }

  return (
    <FormControl>
      <FormLabel id="date-select">
          <Typography style={{ color: "var(--black)", marginBottom: 8 }} variant="h5">Turnover Date</Typography>
      </FormLabel>
      <Stack direction={"row"} justifyContent={""} style={{ marginTop: 8 }}>

        <DateSelectDropDown label={"month"} value={months[selectedMonth - 1].slice(0, 3)} options={monthOptions}
                            width={83}
                            onChange={(event) => setSelectedMonth(shortMonths.indexOf(event.target.value) + 1)} />
        <DateSelectDropDown label={"year"} value={selectedYear} options={yearOptions}
                            onChange={(event) => setSelectedYear(event.target.value)} />
      </Stack>
    </FormControl>
)

}

const DateSelectDropDown = ({label, value, options, onChange, width}) => {

  return (
    <FormControl fullWidth style={{ flex: 1 }}>
      <DropDownOutlined
        id={label.replaceAll(" ", "-") + "-select"}
        value={value}
        label={label}
        onChange={onChange}
        style={{ width }}
      >
        {options.map(d => (
          <MenuItem key={d} value={d}>{d}</MenuItem>
        ))}
      </DropDownOutlined>
    </FormControl>
  )
}

export default DateSelect;
