/**
 * For units with complex shapes, move the labels for some units slightly in x and y so that the labels fall within
 * the bounds of the shape.
 */
const labelOffsets = {
  2700: [-4, 0],  // H Samuel
  4200: [4, 0],  // Goldsmiths
  13250: [4, 0], // Office
  11600: [6, 0],  // Vans
  11850: [6, 2],  // Browns
  11900: [-4, 4],  // Pandora
  12700: [-2, -2],  // EE
}

export default labelOffsets;