import IconButton, { IconButtonProps } from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import { colours } from "../../../theme";
import { PropsWithChildren, useState } from "react";
import Badge, { BadgeProps } from "@mui/material/Badge";
import Tooltip from "@mui/material/Tooltip";

export interface CustomIconButtonProps extends PropsWithChildren {
  active: boolean;
  badgeCount?: number;
  tooltip: string;
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

export default function UnitDetailsIconButton(props: CustomIconButtonProps) {
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);

  const handleTooltipOpen = () => {
    setIsTooltipOpen(true);
  };

  const handleTooltipClose = () => {
    setIsTooltipOpen(false);
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    handleTooltipClose();
    props.onClick(event);
  };

  const CustomIconButton = styled(IconButton)<IconButtonProps>(({ theme }) => ({
    color: props.active ? colours.red : colours.black,
    backgroundColor: props.active ? colours.pink : colours.grey4,
    "&:hover": {
      backgroundColor: colours.pink,
      color: colours.red,
    },
  }));

  const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
    "& .MuiBadge-badge": {
      right: -2,
      top: -2,
      border: `2px solid ${theme.palette.background.paper}`,
      padding: "0 4px",
      fontSize: 11,
      backgroundColor: "red",
      color: "white",
    },
  }));

  if (props.badgeCount) {
    return (
      <Tooltip
        title={props.tooltip}
        placement="top"
        open={isTooltipOpen}
        onOpen={handleTooltipOpen}
        onClose={handleTooltipClose}
      >
        {/* This span is required to stop the component from excessively re-rendering. */}
        <span>
          <CustomIconButton
            sx={{ width: 40, height: 40 }}
            onClick={handleClick}
          >
            <StyledBadge badgeContent={props.badgeCount}>
              {props.children}
            </StyledBadge>
          </CustomIconButton>
        </span>
      </Tooltip>
    );
  }

  return (
    <Tooltip
      title={props.tooltip}
      placement="top"
      open={isTooltipOpen}
      onOpen={handleTooltipOpen}
      onClose={handleTooltipClose}
    >
      {/* This span is required to stop the component from excessively re-rendering. */}
      <span>
        <CustomIconButton sx={{ width: 40, height: 40 }} onClick={handleClick}>
          {props.children}
        </CustomIconButton>
      </span>
    </Tooltip>
  );
}

export interface UnitDetailsIconButtonProps {
  active: boolean;
}
