import DetailsTab from "./DetailsTab";
import OtherUnitsTab from "./OtherUnitsTab";
import DocsTab from "./DocsTab";
import NotesTab from "./NotesTab";
import { LeasingScheduleUnit } from "../../../types/LeasingScheduleUnit";

export type Tab = "details" | "other-units" | "notes" | "docs";

export interface TabGroupProps extends TabProps {
  currentTab: Tab;
}

export default function TabGroup(props: TabGroupProps) {
  switch (props.currentTab) {
    case "details":
      return <DetailsTab {...props} />;
    case "other-units":
      return <OtherUnitsTab {...props} />;
    case "notes":
      return <NotesTab {...props} />;
    case "docs":
      return <DocsTab {...props} />;
    default:
      return null;
  }
}

export interface TabProps {
  unitData: LeasingScheduleUnit;
  otherUnitsData: LeasingScheduleUnit[];
  handleClickUnit: () => void;
  isHighlightingUnits: boolean;
  setIsHighlightingUnits: (foo: boolean) => void;
  comparisonUnits: any[];
  handleClickCompareOtherUnits: (ids: number[]) => void;
  isVerifiedUser: boolean;
  handleRefreshNotesForUnit: () => void;
  handleError: () => void;
  notes: any[];
  selectedUnit: number;
  handleEditUnit: (
    unit_document_id: string,
    unitId: number,
    updatedFields: any
  ) => void;
  isKiosk: boolean;
  isStorage: boolean;
  isInternal: boolean;
  unitTurnoverData: any;
}
